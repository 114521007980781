import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'auth-crypto-unavailable',
  templateUrl: './crypto-unavailable.component.html',
  styleUrls: ['./crypto-unavailable.component.css']
})
export class CryptoUnavailableComponent implements OnInit {
  opened = false;

  constructor(@Inject("Window") private window: Window) { }

  ngOnInit(): void {
    this.opened = !this.window.crypto?.subtle;
  }

}

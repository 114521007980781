<clr-modal [(clrModalOpen)]="opened">
	<h3 class="modal-title">{{ title }}</h3>
	<div class="modal-body">
		<p>
			{{ message }}
		</p>
	</div>
	<div class="modal-footer">
		<button class="btn btn-primary" type="button" (click)="opened = false">Ok</button>
	</div>
</clr-modal>

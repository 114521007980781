import { Pipe, PipeTransform } from '@angular/core';

// Formats a decimal number according to current locale's rules.
// The input value is expected to be in 100ths of the currency's unit.
// If the amount is positive, then the returned string is always prefixed with '+'.
@Pipe({
  name: 'transactionAmount'
})
export class TransactionAmountPipe implements PipeTransform {

  transform(amount: number): string {
    let str = (amount / 100).toLocaleString();
    if (amount > 0)
      str = '+' + str;
    return str;
  }

}

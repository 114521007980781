export interface RadiomicsFeatureGroups {
	[id: string]: RadiomicsFeatureGroup;
}

export interface RadiomicsFeatureGroup {
	friendlyName: string;
	features: RadiomicsFeature[];
}

export interface RadiomicsFeature {
	id: string;
	friendlyName?: string;
	multimodal?: boolean;
} 

export const definedRadiomicsFeatures: RadiomicsFeatureGroups = {
    "stat": {
		friendlyName: "Intensity-based Statistical (STAT) features",
		features: [
			{ id: 'stat.mean', friendlyName: "Mean intensity" },
			{ id: 'stat.sum', friendlyName: "Sum intensity" },
			{ id: 'stat.var', friendlyName: "Intensity variance" },
			{ id: 'stat.skew', friendlyName: "Intensity skewness" },
			{ id: 'stat.kurt', friendlyName: "(Excess) intensity kurtosis" },
			{ id: 'stat.median', friendlyName: "Median intensity" },
			{ id: 'stat.min', friendlyName: "Minimum intensity" },
			{ id: 'stat.P10', friendlyName: "10th intensity percentile" },
			{ id: 'stat.P90', friendlyName: "90th intensity percentile" },
			{ id: 'stat.max', friendlyName: "Maximum intensity" },
			{ id: 'stat.iqr', friendlyName: "Intensity interquartile range" },
			{ id: 'stat.range', friendlyName: "Intensity range" },
			{ id: 'stat.mad', friendlyName: "Intensity-based mean absolute deviation" },
			{ id: 'stat.rmad', friendlyName: "Intensity-based robust mean absolute deviation" },
			{ id: 'stat.medad', friendlyName: "Intensity-based median absolute deviation" },
			{ id: 'stat.cov', friendlyName: "Intensity-based coefficient of variation" },
			{ id: 'stat.qcod', friendlyName: "Intensity-based quartile coefficient of dispersion" },
			{ id: 'stat.energy', friendlyName: "Intensity-based energy" },
			{ id: 'stat.rms', friendlyName: "Root mean square intensity" },
			{ id: 'stat.peak.local', friendlyName: "Local intensity peak" },
			{ id: 'stat.peak.global', friendlyName: "Global intensity peak" }		  
		],
    },
	"ih": {
		friendlyName: "Intensity Histogram (IH) features",
		features: [
			{ id: 'ih.mean', friendlyName: "Mean discretised intensity" },
			{ id: 'ih.var', friendlyName: "Discretised intensity variance" },
			{ id: 'ih.skew', friendlyName: "Discretised intensity skewness" },
			{ id: 'ih.kurt', friendlyName: "(Excess) discretised intensity kurtosis" },
			{ id: 'ih.median', friendlyName: "Median discretised intensity" },
			{ id: 'ih.min', friendlyName: "Minimum discretised intensity" },
			{ id: 'ih.max', friendlyName: "Maximum discretised intensity" },
			{ id: 'ih.mode', friendlyName: "Intensity histogram mode" },
			{ id: 'ih.mad', friendlyName: "Intensity histogram mean absolute deviation" },
			{ id: 'ih.rmad', friendlyName: "Intensity histogram robust mean absolute deviation" },
			{ id: 'ih.medad', friendlyName: "Intensity histogram median absolute deviation" },
			{ id: 'ih.cov', friendlyName: "Intensity histogram coefficient of variation" },
			{ id: 'ih.qcod', friendlyName: "Intensity histogram quartile coefficient of dispersion" },
			{ id: 'ih.entropy', friendlyName: "Discretised intensity entropy" },
			{ id: 'ih.uniformity', friendlyName: "Discretised intensity uniformity" },
			{ id: 'ih.max.grad', friendlyName: "Maximum histogram gradient" },
			{ id: 'ih.max.grad.gl', friendlyName: "Maximum histogram gradient intensity" },
			{ id: 'ih.min.grad', friendlyName: "Minimum histogram gradient" },
			{ id: 'ih.min.grad.gl', friendlyName: "Minimum histogram gradient intensity" }		  
		],
	},
	"ivh": {
		friendlyName: "Intensity Volume Histogram (IVH) features",
		features: [
			{ id: 'ivh.V10', friendlyName: "Volume at intensity fraction 10%" },
			{ id: 'ivh.V90', friendlyName: "Volume at intensity fraction 90%" },
			{ id: 'ivh.I10', friendlyName: "Intensity at volume fraction 10%" },
			{ id: 'ivh.I90', friendlyName: "Intensity at volume fraction 90%" },
			{ id: 'ivh.V10minusV90', friendlyName: "Volume fraction difference between intensity fractions" },
			{ id: 'ivh.I10minusI90', friendlyName: "Intensity fraction difference between volume fractions" }
		],
	},
	"ngt": {
		friendlyName: "Neighborhood Gray Tone Difference Matrix (NGT) features",
		features: [
			{ id: 'ngt.coarseness', friendlyName: "Coarseness" },
			{ id: 'ngt.contrast', friendlyName: "Contrast" },
			{ id: 'ngt.busyness', friendlyName: "Busyness" },
			{ id: 'ngt.complexity', friendlyName: "Complexity" },
			{ id: 'ngt.strength', friendlyName: "Strength" }		  
		],
	},
	"cm": {
		friendlyName: "Gray Level Co-occurrence Matrix (CM) features",
		features: [
			{ id: 'cm.joint.max', friendlyName: "Joint maximum" },
			{ id: 'cm.joint.avg', friendlyName: "Joint average" },
			{ id: 'cm.joint.var', friendlyName: "Joint variance" },
			{ id: 'cm.joint.entr', friendlyName: "Joint entropy" },
			{ id: 'cm.diff.avg', friendlyName: "Difference average" },
			{ id: 'cm.diff.var', friendlyName: "Difference variance" },
			{ id: 'cm.diff.entr', friendlyName: "Difference entropy" },
			{ id: 'cm.sum.avg', friendlyName: "Sum average" },
			{ id: 'cm.sum.var', friendlyName: "Sum variance" },
			{ id: 'cm.sum.entr', friendlyName: "Sum entropy" },
			{ id: 'cm.energy', friendlyName: "Angular second moment" },
			{ id: 'cm.contrast', friendlyName: "Contrast" },
			{ id: 'cm.dissimilarity', friendlyName: "Dissimilarity" },
			{ id: 'cm.inv.diff', friendlyName: "Inverse difference" },
			{ id: 'cm.inv.diff.norm', friendlyName: "Normalised inverse difference" },
			{ id: 'cm.inv.diff.mom', friendlyName: "Inverse difference moment" },
			{ id: 'cm.inv.diff.mom.norm', friendlyName: "Normalised inverse difference moment" },
			{ id: 'cm.inv.var', friendlyName: "Inverse variance" },
			{ id: 'cm.corr', friendlyName: "Correlation" },
			{ id: 'cm.auto.corr', friendlyName: "Autocorrelation" },
			{ id: 'cm.clust.shade', friendlyName: "Cluster shade" },
			{ id: 'cm.clust.prom', friendlyName: "Cluster prominence" },
			{ id: 'cm.info.corr.1', friendlyName: "Information correlation 1" },
			{ id: 'cm.info.corr.2', friendlyName: "Information correlation 2" }		  
		],
	},
	"szm": {
		friendlyName: "Gray Level Size Zone Matrix (SZM) features",
		features: [
			{ id: 'szm.sze', friendlyName: "Small zone emphasis" },
			{ id: 'szm.lze', friendlyName: "Large zone emphasis" },
			{ id: 'szm.lgze', friendlyName: "Low grey level zone emphasis" },
			{ id: 'szm.hgze', friendlyName: "High grey level zone emphasis" },
			{ id: 'szm.szlge', friendlyName: "Small zone low grey level emphasis" },
			{ id: 'szm.szhge', friendlyName: "Small zone high grey level emphasis" },
			{ id: 'szm.lzlge', friendlyName: "Large zone low grey level emphasis" },
			{ id: 'szm.lzhge', friendlyName: "Large zone high grey level emphasis" },
			{ id: 'szm.glnu', friendlyName: "Grey level non-uniformity" },
			{ id: 'szm.glnu.norm', friendlyName: "Normalised grey level non-uniformity" },
			{ id: 'szm.zsnu', friendlyName: "Zone size non-uniformity" },
			{ id: 'szm.zsnu.norm', friendlyName: "Normalised zone size non-uniformity" },
			{ id: 'szm.z.perc', friendlyName: "Zone percentage" },
			{ id: 'szm.gl.var', friendlyName: "Grey level variance" },
			{ id: 'szm.zs.var', friendlyName: "Zone size variance" },
			{ id: 'szm.zs.entr', friendlyName: "Zone size entropy" }		  
		],
	},
	"dzm": {
		friendlyName: "Gray Level Distance Zone Matrix (DZM) features",
		features: [
			{ id: 'dzm.sde', friendlyName: "Small distance emphasis" },
			{ id: 'dzm.lde', friendlyName: "Large distance emphasis" },
			{ id: 'dzm.lgze', friendlyName: "Low grey level zone emphasis" },
			{ id: 'dzm.hgze', friendlyName: "High grey level zone emphasis" },
			{ id: 'dzm.sdlge', friendlyName: "Small distance low grey level emphasis" },
			{ id: 'dzm.sdhge', friendlyName: "Small distance high grey level emphasis" },
			{ id: 'dzm.ldlge', friendlyName: "Large distance low grey level emphasis" },
			{ id: 'dzm.ldhge', friendlyName: "Large distance high grey level emphasis" },
			{ id: 'dzm.glnu', friendlyName: "Grey level non-uniformity" },
			{ id: 'dzm.glnu.norm', friendlyName: "Normalised grey level non-uniformity" },
			{ id: 'dzm.zdnu', friendlyName: "Zone distance non-uniformity" },
			{ id: 'dzm.zdnu.norm', friendlyName: "Normalised zone distance non-uniformity" },
			{ id: 'dzm.z.perc', friendlyName: "Zone percentage" },
			{ id: 'dzm.gl.var', friendlyName: "Grey level variance" },
			{ id: 'dzm.zd.var', friendlyName: "Zone distance variance" },
			{ id: 'dzm.zd.entr', friendlyName: "Zone distance entropy" }		  
		],
	},
	"rlm": {
		friendlyName: "Gray Level Run Length Matrix (RLM) features",
		features: [
			{ id: 'rlm.sre', friendlyName: "Short runs emphasis" },
			{ id: 'rlm.lre', friendlyName: "Long runs emphasis" },
			{ id: 'rlm.lgre', friendlyName: "Low grey level run emphasis" },
			{ id: 'rlm.hgre', friendlyName: "High grey level run emphasis" },
			{ id: 'rlm.srlge', friendlyName: "Short run low grey level emphasis" },
			{ id: 'rlm.srhge', friendlyName: "Short run high grey level emphasis" },
			{ id: 'rlm.lrlge', friendlyName: "Long run low grey level emphasis" },
			{ id: 'rlm.lrhge', friendlyName: "Long run high grey level emphasis" },
			{ id: 'rlm.glnu', friendlyName: "Grey level non-uniformity" },
			{ id: 'rlm.glnu.norm', friendlyName: "Normalised grey level non-uniformity" },
			{ id: 'rlm.rlnu', friendlyName: "Run length non-uniformity" },
			{ id: 'rlm.rlnu.norm', friendlyName: "Normalised run length non-uniformity" },
			{ id: 'rlm.r.perc', friendlyName: "Run percentage" },
			{ id: 'rlm.gl.var', friendlyName: "Grey level variance" },
			{ id: 'rlm.rl.var', friendlyName: "Run length variance" },
			{ id: 'rlm.rl.entr', friendlyName: "Run entropy" }		  
		],
	},
	"ngl": {
		friendlyName: "Neighboring Gray Level Dependence (NGL) features",
		features: [
			{ id: 'ngl.lde', friendlyName: "Low dependence emphasis" },
			{ id: 'ngl.hde', friendlyName: "High dependence emphasis" },
			{ id: 'ngl.lgce', friendlyName: "Low grey level count emphasis" },
			{ id: 'ngl.hgce', friendlyName: "High grey level count emphasis" },
			{ id: 'ngl.ldlge', friendlyName: "Low dependence low grey level emphasis" },
			{ id: 'ngl.ldhge', friendlyName: "Low dependence high grey level emphasis" },
			{ id: 'ngl.hdlge', friendlyName: "High dependence low grey level emphasis" },
			{ id: 'ngl.hdhge', friendlyName: "High dependence high grey level emphasis" },
			{ id: 'ngl.glnu', friendlyName: "Grey level non-uniformity" },
			{ id: 'ngl.glnu.norm', friendlyName: "Normalised grey level non-uniformity" },
			{ id: 'ngl.dcnu', friendlyName: "Dependence count non-uniformity" },
			{ id: 'ngl.dcnu.norm', friendlyName: "Normalised dependence count non-uniformity" },
			{ id: 'ngl.dc.perc', friendlyName: "Dependence count percentage" },
			{ id: 'ngl.gl.var', friendlyName: "Grey level variance" },
			{ id: 'ngl.dc.var', friendlyName: "Dependence count variance" },
			{ id: 'ngl.dc.entr', friendlyName: "Dependence count entropy" },
			{ id: 'ngl.dc.energy', friendlyName: "Dependence count energy" },
		],
	},
	"morph": {
		friendlyName: "Morphological (MORPH) features",
		features: [
			{ id: 'morph.approx.vol', friendlyName: "Volume (voxel counting)" },
			{ id: 'morph.moran.i', friendlyName: "Moran's I index" },
			{ id: 'morph.geary.c', friendlyName: "Geary's C measure" },
			{ id: 'morph.com', friendlyName: "Centre of mass shift" },
			{ id: 'morph.diam', friendlyName: "Maximum 3D diameter" },
			{ id: 'morph.v.dens.aabb', friendlyName: "Volume density (axis-aligned bounding box)" },
			{ id: 'morph.a.dens.aabb', friendlyName: "Area density (axis-aligned bounding box)" },
			{ id: 'morph.vol', friendlyName: "Volume (mesh)" },
			{ id: 'morph.area', friendlyName: "Surface area (mesh)" },
			{ id: 'morph.av', friendlyName: "Surface to volume ratio" },
			{ id: 'morph.comp.1', friendlyName: "Compactness 1" },
			{ id: 'morph.comp.2', friendlyName: "Compactness 2" },
			{ id: 'morph.sph.dispr', friendlyName: "Spherical disproportion" },
			{ id: 'morph.sphericity', friendlyName: "Sphericity" },
			{ id: 'morph.asphericity', friendlyName: "Asphericity" }		  
		],
	},
	"fuse": {
		friendlyName: "Fusion (FUSE) features",
		features: [
			{ id: "fuse.joint.max", multimodal: true, friendlyName: "Joint maximum" },
			{ id: "fuse.joint.avg", multimodal: true, friendlyName: "Joint average" },
			{ id: "fuse.joint.var", multimodal: true, friendlyName: "Joint variance" },
			{ id: "fuse.joint.entr", multimodal: true, friendlyName: "Joint entropy" },
			{ id: "fuse.diff.avg", multimodal: true, friendlyName: "Difference average" },
			{ id: "fuse.diff.var", multimodal: true, friendlyName: "Difference variance" },
			{ id: "fuse.diff.entr", multimodal: true, friendlyName: "Difference entropy" },
			{ id: "fuse.sum.avg", multimodal: true, friendlyName: "Sum average" },
			{ id: "fuse.sum.var", multimodal: true, friendlyName: "Sum variance" },
			{ id: "fuse.sum.entr", multimodal: true, friendlyName: "Sum entropy" },
			{ id: "fuse.energy", multimodal: true, friendlyName: "Angular second moment" },
			{ id: "fuse.contrast", multimodal: true, friendlyName: "Contrast" },
			{ id: "fuse.dissimilarity", multimodal: true, friendlyName: "Dissimilarity" },
			{ id: "fuse.inv.diff", multimodal: true, friendlyName: "Inverse difference" },
			{ id: "fuse.inv.diff.norm", multimodal: true, friendlyName: "Normalised inverse difference" },
			{ id: "fuse.inv.diff.mom", multimodal: true, friendlyName: "Inverse difference moment" },
			{ id: "fuse.inv.diff.mom.norm", multimodal: true, friendlyName: "Normalised inverse difference moment" },
			{ id: "fuse.inv.var", multimodal: true, friendlyName: "Inverse variance" },
			{ id: "fuse.corr", multimodal: true, friendlyName: "Correlation" },
			{ id: "fuse.auto.corr", multimodal: true, friendlyName: "Autocorrelation" },
			{ id: "fuse.clust.shade", multimodal: true, friendlyName: "Cluster shade" },
			{ id: "fuse.clust.prom", multimodal: true, friendlyName: "Cluster prominence" },
			{ id: "fuse.info.corr.1", multimodal: true, friendlyName: "Information correlation 1" },
			{ id: "fuse.info.corr.2", multimodal: true, friendlyName: "Information correlation 2" },
			{ id: "fuse.norm.mut.info", multimodal: true, friendlyName: "Normalized mutual information" },
		],
	},
};

import { NgModule } from '@angular/core';
import { DicomService } from './dicom.service';
import { ModelBackendService } from './model-backend.service';
import { FileUploadService, SubmissionBackendService } from '../public-api';
import { ToFixedPipe } from './to-fixed.pipe';
import { DicomDatePipe } from './dicom-date.pipe';



@NgModule({
  declarations: [
    ToFixedPipe,
    DicomDatePipe,
  ],
  imports: [
  ],
  exports: [
    ToFixedPipe,
    DicomDatePipe,
  ],
  providers: [
    //DicomService,
    //ModelBackendService,
    //SubmissionBackendService,
    //FileUploadService,
    //{ provide: "BASE_URL", useValue: globalThis.window?.location.origin },
    ToFixedPipe,
    DicomDatePipe,
  ]
})
export class CdssCommonLibModule { }

<div class="login-screen">
    <div class="login-wrapper">
        <div class="login">
            <p *ngIf="!showMuwStrings" i18n>
				We're sorry, but your account has not yet been activated by our administrator.
			</p>
            <p *ngIf="showMuwStrings">
				We're sorry, but your account has not yet been activated by MUV ITSC administrator.
			</p>
        </div>
    </div>
    <div class="logo-wrapper">
        <img class="logo the-logo" alt="Dedicaid" />
    </div>
</div>

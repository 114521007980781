import { DicomRole, DicomTagMatch, ExtractedDicomTag } from "../dicom-role";
import { ProjectTemplateBackground, ProjectTemplateExtractedFeature, ProjectTemplateMaskHandling } from "../radiomics-common";

export interface CdssModel {
	name: string;
	description: string;
	public: boolean;
	organ: string;
	dlPipeline: string;
	data: CdssModelData;
	reportContents?: string;
	reportContentsQC?: string;
	rendering: CdssRendering;
	renderingEngine: RenderingEngineType;
  
	// Includes all values from data.labels.assignedAutomlModel
	assignedAutomlModels?: string[];
  
	// Article key for payment
	articleKey: string;
	presubmissionCheckedMasks?: string[];
}

export interface CdssModelData {
	bodyRegions?: CdssBodyRegion[];
	extractedFeatures: ProjectTemplateExtractedFeature[];
	roles: CdssRole[];
	backgrounds: ProjectTemplateBackground[];
	maskHandling: ProjectTemplateMaskHandling;
	labels: CdssLabelDefinition[];
	manualFeatures: CdssManualFeatureDefinition[];
	indications: CdssIndication[];
}


export const bodyRegionPalettes: CdssRenderingPaletteMap = {
	"region-mask": {
	  low: 0.25,
	  high: 1,
	  builtin: "region-lower-bound",
	},
  };
  
  export interface ModelListItem {
	id: string;
	name: string;
	description: string;
	public: boolean;
	organ: string;
}
export interface AutoMLPerformanceValues {
	ACC: number;
	NPV: number;
	PPV: number;
	SNS: number;
	SPC: number;
	AUC?: number;
}
  
export interface AutoMLPublicPerformance {
	performance: AutoMLPerformanceValues[];
	folds: number;
	split: {
	  validation: number;
	  training: number;
	};
	cdssModelBuilderDescription?: string;
}
  
export interface CdssIndication {
	text: string;
}
  
export interface CdssRendering {
	scenes: CdssRenderingScene[];
	palettes: CdssRenderingPaletteMap;
	expressions: CdssRenderingExpressionMap;
}
  
// The images field is generated from CdssModelData and deepLearningPipelines
export interface CdssRenderingFile extends CdssRendering {
	images: {[ id: string ]: string};
}
  
export type CameraView = "coronal" | "axial" | "sagittal";
export interface CdssRenderingScene {
	name: string;
	bgColor?: string;
	camera: CameraView | CameraView[];
	contents: CdssRenderingSceneContent[];
	format?: string;
	// Resolution override
	resolution?: number;
	// Special overrides for HV renders.
	// Informs the system that the region self mask should be rendered not relative to self, but to something else.
	// See bodyRegionMaskScenes().
	maskCanvasSetter?: string;
	maskCanvasDilation?: number;
	legends?: CdssHVLegend[];
	showOrientationLabels?: boolean;
}
  
export interface CdssHVLegend {
	title: string; // e.g. "Risk"
	items: CdssHVLegendItem[]; // individual labeled colors
}
  
export interface CdssHVLegendItem {
	label: string; // label for this color
	color: string; // either #rrggbb or an inline SVG
}
  
export type CdssRenderingSceneContent = CdssRenderingSceneMIP | CdssRenderingScenePaletteLegend | CdssRenderingSceneMIPOutline 
	| CdssRenderingSceneMIPDotmap | CdssRenderingSceneSprite | CdssRenderingSceneCVR;
  
interface CdssRenderingSceneMIPCommon {
	id: string;
	image: string;
	maskImage?: string;
	maskMode?: "precise";
	normalizeBy?: string;
	normalizeByExpr?: string;
	weightedOpacity?: boolean;
	maskDilation?: number; // default is 10mm
	palette: string;
	opacity?: number;
}
  
export interface CdssRenderingSceneMIP extends CdssRenderingSceneMIPCommon {
	program: "mip";
}
  
export interface CdssRenderingSceneMIPOutline extends CdssRenderingSceneMIPCommon {
	program: "mip-outline";
	outlineColor: string;
}
  
export interface CdssRenderingSceneMIPDotmap extends CdssRenderingSceneMIPCommon {
	program: "mip-dotmap";
	dotmapColor?: string;
	disableCircle?: boolean;
}
  
export interface CdssRenderingScenePaletteLegend {
	program: "palette";
	source: string;
	highText?: string;
	lowText?: string;
	unitText?: string;
	location: "left" | "right";
	fgColor?: string;
}
  
export interface CdssRenderingSceneCVR {
	program: "cvr";
	image: string;
	opacity?: number;
	config: string;
	resolution?: number;
	maskImage?: string;
	maskDilation?: number;
}
  
export interface CdssRenderingSceneSprite {
	program: "sprite";
	image: string;
	locator: string;
	maskImage?: string;
	maskDilation?: number;
}
  
  export interface CdssRenderingPaletteMap {
	[id: string]: CdssRenderingPalette;
  }
  
  export interface CdssRenderingExpressionMap {
	[id: string]: string;
  }
  
  export interface CdssRenderingPalette {
	low: number | "auto";
	high: number | "auto";
	builtin: "inverted-gray" | "hot-iron" | "gray" | "hot-metal-blue" | "hot-metal-blue-transparent" | "region-lower-bound";
  }
  
  export interface CdssBodyRegion {
	id: string;
	niceName: string;
	dynamic: boolean;
	voi: string;
	voiHandling: null | "merge" | "split";
	featureExtraction: boolean;
	featureNamePrefix: null | string;
	statistics: CdssBodyRegionStatistic[];
	parentId: string | null;
	hidden?: boolean;
	disallowed?: boolean;
  }
  
  export const DefaultBodyRegion: CdssBodyRegion = {
	id: "default",
	niceName: "Lesion",
	dynamic: false,
	voi: ".+",
	voiHandling: "merge",
	featureExtraction: false,
	featureNamePrefix: null,
	statistics: [],
	parentId: null,
  };
  
  export interface CdssBodyRegionStatistic {
	feature: string;
	niceName: string;
  }
  
  export interface CdssLabelDefinition {
	name: string;
	niceName?: string;
	description?: string;
	allowedValues?: any[];
	allowedValuesExtras?: CdssLabelValueExtras[];
	assignedAutomlModel?: string;
	bodyRegion?: string; // default to "default"
  }
  
  export interface CdssManualFeatureDefinition {
	name: string;
	niceName?: string;
	allowedValues?: any[];
	allowedValuesExtras?: CdssFeatureValueExtras[];
	forceNumeric?: boolean;
	required?: boolean;
  }
  
  export type CdssFeatureValueExtras = CdssLabelValueExtras;
  
  export interface CdssLabelValueExtras {
	niceName: string; // Nice name for that value (e.g. "Low")
	// Not used for manual features:
	color?: string; // HTML color code for this label value
  }
  
  export type CdssRole = DicomRole;
  export type CdssExtractedDicomTag = ExtractedDicomTag;
  export type CdssDicomTagMatch = DicomTagMatch;
  
  export type RenderingEngineType = "cpu" | "gpu";
  
  export interface SuitableAutoMLModel {
	id: string;
	name: string;
	createdAt: Date;
	outcomeColumnName: string;
	allTags: { [key: string]: string; };
}
  
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BASE_URL } from '../dicom.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient, @Inject(BASE_URL) private baseUrl: string) { }

  public createBatch(): Observable<string> {
    return this.http.post(this.baseUrl + '/file-upload/batch', "", { observe: "response" }).pipe(map(response => {
      let locationValue = response.headers.get('location');
      return locationValue.split('/').pop();
    }));
  }

  public uploadFile(batchId: string, filename: string, data: Blob | Uint8Array | ArrayBuffer) {
    return this.http.put(`${this.baseUrl}/file-upload/batch/${batchId}/files/${filename}`, data, { observe: "events", reportProgress: true });
  }

  public downloadFile(batchId: string, filename: string) {
    return this.http.get(`${this.baseUrl}/file-upload/batch/${batchId}/files/${filename}`, { observe: "events", reportProgress: true, responseType: 'arraybuffer' });
  }
}

import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { AnalyzeCommand, AnalyzeCommandResult, ConvertCommand, ConvertCommandResult, ConvertRoiCommand, ConvertRoiResult, ExportRoiCommand, ExportRoiResult, FileSelection, NamedBlob } from './dicom-common';

export const BASE_URL = new InjectionToken("BASE_URL");
export const DICOM_WORKER = new InjectionToken("DICOM_WORKER");

@Injectable({
  providedIn: 'root'
})
export class DicomService implements OnDestroy {
  private worker: Worker;

  private convertResolve: ((niftiFile: ArrayBuffer) => void)[] = [];
  private convertReject: ((err: Error) => void)[] = [];

  private analyzeResolve: ((result: DicomAnalysisResult) => void)[] = [];
  private analyzeReject: ((err: Error) => void)[] = [];

  private exportRoiResolve: ((result: ExportRoiResult) => void)[] = [];
  private exportRoiReject: ((err: Error) => void)[] = [];

  private convertRoiResolve: ((result: ArrayBuffer) => void)[] = [];
  private convertRoiReject: ((err: Error) => void)[] = [];

  private externalWorker = false;

  constructor(@Inject(BASE_URL) baseUrl: string, @Inject(DICOM_WORKER) worker?: () => Worker) {
    if (globalThis.Worker) {
      this.externalWorker = true;
      this.worker = worker();//new Worker(dicomWorkerUrl, { type: 'module', name: 'dicom' });
      this.worker.onmessage = msg => this.onWorkerMessage(msg);
    } else {
      import('worker_threads')
      .then(wt => {
        const worker = new wt.Worker(`${baseUrl}/dicom.worker-node.js`);
        worker.addListener("message", msg => this.onWorkerMessage(msg));
        this.worker = worker as unknown as Worker;
      });
    }
  }

  ngOnDestroy(): void {
    if (!this.externalWorker)
      this.worker.terminate();
  }

  public convertToNifti(dicomFiles: NamedBlob[] | FileSelection, outputName: string, normalizeByValue?: number): Promise<ArrayBuffer> {

    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.convertResolve.push(resolve);
      this.convertReject.push(reject);

      let msg: ConvertCommand = {
        command: "convert",
        inputFiles: dicomFiles,
        outputName,
        normalizeByValue,
      };

      this.worker.postMessage(msg);
    });
  }

  public convertRoiToNifti(dicomFile: Blob, roiNumber: number): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.convertRoiResolve.push(resolve);
      this.convertRoiReject.push(reject);

      let msg: ConvertRoiCommand = {
        command: "convert-roi",
        file: dicomFile,
        roiNumber,
      };

      this.worker.postMessage(msg);
    });
  }

  public analyzeFiles(files: NamedBlob[] | string): Promise<DicomAnalysisResult> {

    return new Promise<DicomAnalysisResult>((resolve, reject) => {
      this.analyzeResolve.push(resolve);
      this.analyzeReject.push(reject);

      let msg: AnalyzeCommand = {
        command: "analyze",
        files,
      };

      this.worker.postMessage(msg);
    });
  }

  public exportRoi(file: Blob, roiNumber: number): Promise<ExportRoiResult> {
    return new Promise<ExportRoiResult>((resolve, reject) => {
      this.exportRoiResolve.push(resolve);
      this.exportRoiReject.push(reject);

      let msg: ExportRoiCommand = {
        command: "export-roi",
        file,
        roiNumber,
      };

      this.worker.postMessage(msg);
    });
  }

  private onWorkerMessage(event: MessageEvent<any>) {
    switch (event.data.command) {
      case "convert": {
        let e = <ConvertCommandResult> event.data;

        let resolve = this.convertResolve.shift();
        let reject = this.convertReject.shift();

        if (e.exitCode === 0) {
          resolve(e.fileData);
        } else {
          reject(new Error(`Failed with exit code ${e.exitCode}`));
        }
        break;
      }
      case "analyze": {
        let e = <AnalyzeCommandResult> event.data;

        let resolve = this.analyzeResolve.shift();
        let reject = this.analyzeReject.shift();

        if (e.jsonResult) {
          resolve(JSON.parse(e.jsonResult));
        } else {
          reject(new Error(`Analyzer failure`));
        }
        break;
      }
      case "export-roi": {
        let e = event.data as ExportRoiResult;

        let resolve = this.exportRoiResolve.shift();
        let reject = this.exportRoiReject.shift();

        if (e.contours !== null) {
          resolve(e);
        } else {
          reject(new Error("Incorrect ROI number or missing contour data"));
        }

        break;
      }
      case "convert-roi": {
        let e = event.data as ConvertRoiResult;

        let resolve = this.convertRoiResolve.shift();
        let reject = this.convertRoiReject.shift();

        if (e.fileData) {
          resolve(e.fileData);
        } else {
          reject(new Error("DICOM-RT ROI conversion failed"));
        }
      }
    }
  }
}

export interface DicomAnalysisResult {
  patients: DicomAnalysisResultPatient[];
}

export interface DicomAnalysisResultPatient {
  birthDate: string;
  id: string;
  name: string;
  issuerOfPatientId: string;
  sex: "M" | "F"; // FIXME: This value is not 100% standardized, also expect "Weiblich/Mannlich" etc.
  studies: DicomAnalysisResultStudy[];
}

export interface DicomAnalysisResultStudy {
  date: string;
  description: string;
  id: string;
  time: string;
  uid: string;
  series: DicomAnalysisResultSerie[];
  patientAge?: string;
  patientWeight?: number;
  patientSize?: number;
  referringPhysician?: string;
  institutionName?: string;
}

export interface DicomAnalysisResultSerie {
  description: string;
  filenames: string[];
  modality: string;
  uid: string;
  units?: string;

  imageType: string;
  correctedImage: string;
  repetitionTime?: number;
  echoTime?: number;
  hasMriDiffusionSeq?: boolean;

  radionuclide?: string; // Code meaning
  radionuclideCode?: string; // Code value
  radionuclideDose?: number;
  radionuclideHalfLife?: number;

  radiopharmaceutical?: string; // Code meaning
  radiopharmaceuticalCode?: string; // Code value

  radiopharmaceuticalStartTime?: string;
  seriesTime?: string;

  // If modality === "RTSTRUCT"
  rois?: DicomRoi[];

  imageAttributes?: DicomImageAttributes;
  mediaStorageType?: "CTImageStorage" | "PositronEmissionTomographyImageStorage" | "MRImageStorage" | string;
}

export interface DicomRoi {
  number: number;
  name: string;
  color: string; // RGB 0-255, separated by '\'
}

export interface DicomImageAttributes {
  // Voxel count
  dimensions: number[];

  // Voxel size
  spacing: number[];

  // The above [x,y,z] information corresponds to:
  // x: left/right
  // y: anterior/posterior
  // z: superior/inferior
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { AuthenticationService } from '../../auth.service';

@Component({
  selector: 'auth-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.css']
})
export class ForgottenPasswordComponent {
  opened = false;
  busy = false;
  email: string;

  formGroup = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.email ]),
  });

  @ViewChild(ClrForm)
  clrForm: ClrForm;

  constructor(private authService: AuthenticationService) { }

  public open() {
    this.formGroup.get('email').setValue(this.email);
    this.opened = true;
  }

  sendRecoveryEmail() {
    if (!this.formGroup.valid) {
      this.clrForm.markAsTouched();
      return;
    }

    this.busy = true;

    this.authService.requestForgottenPasswordEmail(this.formGroup.get('email').value).subscribe(() => {
      this.busy = false;
      this.opened = false;
    }, err => {
      console.error("Failed to request forgotten password e-mail", err);
      window.alert($localize `Failed to request forgotten password e-mail.`);
      this.busy = false;
    });
  }

}

import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { TestingTokenHelper } from 'src/app/charging/testing-token-helper';
import { PaymentService, PaymentState, StripeCardSetupData } from 'src/app/payment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-saved-card',
  templateUrl: './settings-saved-card.component.html',
  styleUrls: ['./settings-saved-card.component.css']
})
export class SettingsSavedCardComponent {
  opened = false;
  busy = false;
  paymentState: PaymentState;

  stripe: Stripe;
  elements: StripeElements;
  cardElement: StripeCardElement;
  stripeSecret: string;

  @ViewChild('cardElement', { static: false }) cardElementNative: ElementRef;

  private cardTestingToken = new TestingTokenHelper(window);

  constructor(private paymentService: PaymentService, private zone: NgZone) { }

  public open() {
    this.paymentState = undefined;
    this.opened = true;

    this.cardTestingToken.register();

    this.paymentService.getPaymentState().subscribe(paymentState => {
      this.paymentState = paymentState;
    });

    this.paymentService.getProviderConfig('stripe').toPromise().then(stripeConfig => {
      loadStripe(stripeConfig['publicKey']).then(stripe => {
        this.stripe = stripe;

        this.zone.runOutsideAngular(() => {
          this.elements = this.stripe.elements({});
          this.cardElement = this.elements.create('card', { hidePostalCode: true });

          this.cardElement.mount(this.cardElementNative.nativeElement);
        });
      });
    });
  }

  forgetCard() {
    this.busy = true;

    this.paymentService.deleteSavedCard()
    .subscribe(() => {

      this.paymentService.getPaymentState().subscribe(paymentState => {
        this.busy = false;
        this.paymentState = paymentState;
      });

    }, err => {
      this.busy = false;
      window.alert(err.message);
    });
  }

  saveCard() {
    this.busy = true;

    this.paymentService.initiateSavedCardSetup().toPromise()
    .then((cardSetup: StripeCardSetupData) => {
      return this.stripe.confirmCardSetup(cardSetup.client_secret, {
        payment_method: {
          card: this.cardTestingToken.token ? { token: this.cardTestingToken.token } : this.cardElement,
        }
      })
    })
    .then(result => {
      if (result.error)
        throw result.error;

      let pmId = result.setupIntent.payment_method;
      return this.paymentService.completeSavedCardSetup(pmId).toPromise();
    })
    .then(() => {
      this.busy = false;
      this.opened = false;
    })
    .catch(err => {
      this.busy = false;
      window.alert(err.message);
    });
  }

}

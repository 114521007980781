<clr-modal [(clrModalOpen)]="modal2FAOpen" [clrModalSize]="'lg'" [clrModalClosable]="!busy">
	<h3 class="modal-title" i18n>Two-Factor Authentication</h3>
	<div class="modal-body">
		<form clrForm [formGroup]="twoFactorForm">
			<div class="not-enrolled" *ngIf="!usesTwoFactor">
				<div class="introduction" *ngIf="!twoFactorSecret">
					<p i18n>
						Using Two-Factor Authentication (2FA) strongly enhances your account's security.
						To get started, <b>you need to install a specialized application</b> on your mobile phone,
						such as Google Authenticator (<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">for Android</a>
						or <a href="https://apps.apple.com/app/google-authenticator/id388497605">for iOS</a>).
					</p>
					<p i18n>
						Once you have installed the application, start it and press the add button to add
						a new code by scanning a barcode provided by this web page.
					</p>
					<p i18n>
						After that, whenever prompted for a 2FA code, start the mobile application and type in the six-digit code.
					</p>
				</div>
				<div class="enrollment">
					<p *ngIf="!twoFactorSecret">
						<clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false" *ngIf="error">
							<clr-alert-item>
								<span class="alert-text" i18n>
									Failed to request 2FA activation.
								</span>
							</clr-alert-item>
						</clr-alert>
						<button type="button" class="btn btn-success" (click)="enroll2FA()" [disabled]="busy" i18n>Enable</button>
					</p>
					<div *ngIf="twoFactorSecret && totpAccountName">
						<qr-code [value]="'otpauth://totp/' + totpAccountName + '?secret=' + twoFactorSecret" [size]="256" errorCorrectionLevel="M"></qr-code>
						<p i18n>
							Please scan the QR code in your mobile application. Then, type in the generated code below to activate the setting.
						</p>
						<clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false" *ngIf="error">
							<clr-alert-item>
								<span class="alert-text" i18n>
									Incorrect two-factor authentication code provided.
								</span>
							</clr-alert-item>
						</clr-alert>
						<p>
							<input name="twoFactorCode" class="clr-input" #twoFactorActivationCode pattern="[0-9]*" inputmode="numeric" autocomplete="off" placeholder="OTP code" formControlName="twoFactorActivationCode" />
						</p>
						<p>
							<button type="button" class="btn btn-primary" (click)="confirm2FA()" [disabled]="busy" i18n>Confirm</button>
						</p>
					</div>
				</div>
			</div>
			<div class="enrolled" *ngIf="usesTwoFactor">
				<p i18n>
					Two-Factor Authentication (2FA) is already active.
				</p>
				<p i18n>
					In case you have purchased a new mobile device and need to transfer the 2FA code generation there, choose to disable 2FA and then
					enable it again, using your new device to read the provided barcode.
				</p>
				<div class="enrollment">
					<clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false" *ngIf="error">
						<clr-alert-item>
							<span class="alert-text" i18n>
								Incorrect two-factor authentication code provided.
							</span>
						</clr-alert-item>
					</clr-alert>
					<p i18n>Type in the six-digit code from your authenticator application:</p>
					<p>
						<input name="twoFactorCode" #twoFactorActivationCode class="clr-input" autocomplete="off" placeholder="OTP code" formControlName="twoFactorActivationCode" />
					</p>
					<p>
						<button type="button" class="btn btn-warning" (click)="disable2FA()" [disabled]="busy" i18n>Disable</button>
					</p>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<div *ngIf="busy">
			<span class="spinner spinner-inline">
				Loading...
			</span>
			<span i18n>
				Working...
			</span>
		</div>
		<button type="button" class="btn btn-outline" (click)="modal2FAOpen = false" [disabled]="busy" i18n>Close</button>
	</div>
</clr-modal>
<app-recovery-codes [recoveryCodes]="recoveryCodes" #recoveryCodesPopup></app-recovery-codes>

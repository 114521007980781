<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="false">
	<h3 class="modal-title" i18n>Disclaimer</h3>
	<div class="modal-body">
		<p>
			Hiermit bestätige ich, dass ich diese Software ausschließlich für interne nicht kommerzielle Forschung und/oder Lehre an der Medizinischen Universität Wien nutze.
		</p>

		<p>
			I confirm herewith that I will use this software exclusively for internal non-commercial research and/or teaching purposes at the Medical University of Vienna.
		</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-success" (click)="accept()" i18n>Accept</button>
	</div>
</clr-modal>

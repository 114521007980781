<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="!busy">
	<h3 class="modal-title" i18n>Forgotten Password</h3>
	<div class="modal-body">
		<form clrForm [formGroup]="formGroup">
			<clr-input-container>
				<label i18n>E-mail</label>
				<input clrInput name="email" type="email" formControlName="email" />
			  </clr-input-container>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="sendRecoveryEmail()" [disabled]="busy" i18n>Send Recovery E-mail</button>
		<button type="button" class="btn btn-outline" (click)="opened = false" [disabled]="busy" i18n>Close</button>
	</div>
</clr-modal>

<ng-template [ngIf]="!error">
	<div class="center">
		<span class="spinner">
			Loading...
		</span>
	</div>
	<p class="center">The account is being activated...</p>
</ng-template>

<ng-template [ngIf]="error">
	<h1 class="center">Account Activation Failed</h1>
	<clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
		<clr-alert-item>
			<span class="alert-text">
				{{ error }}
			</span>
		</clr-alert-item>
	</clr-alert>
</ng-template>

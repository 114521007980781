<div class="login-screen">
    <div class="login-wrapper">
        <div class="login">
            <div class="social-login" *ngIf="loadingSocialLoginProviders || socialLoginProviders.length > 0">
                <h5 class="hint" i18n>Login or register via 3rd-party provider</h5>

                <div class="oauth-alerts" *ngIf="loginErrorMessage">
                    <clr-alert [clrAlertType]="'danger'" [clrAlertIcon]="'exclamation-circle'" [clrAlertClosable]="false">
                        <clr-alert-item>{{ loginErrorMessage }}</clr-alert-item>
                    </clr-alert>
                </div>

                <div *ngIf="loadingSocialLoginProviders">
                    <span class="spinner spinner-inline">
                        Loading...
                    </span>
                    <span i18n>
                        Loading...
                    </span>
                </div>
                
                <button *ngFor="let provider of socialLoginProviders" [ngClass]="['social-button']" [style.background-color]="provider.bgcolor" (click)="redirectToSocialLogin(provider)">
                    <div class="social-icon">
                        <img [src]="'data:image/svg+xml;base64,' + provider.icon" width="26" height="26" />
                    </div>
                    <div class="social-label" i18n>
                        Login with {{ provider.name }}
                    </div>
                </button>
            </div>
            <ng-template [ngIf]="enableEmailLogin">
                <h5 class="hint" i18n>Login via Dedicaid account</h5>
                <form clrForm>
                    <div class="login-group">
                        <clr-input-container>
                            <label class="clr-sr-only" i18n>E-mail</label>
                            <input type="text" name="email" clrInput placeholder="E-mail" [formControl]="email" #emailField autocomplete="email" />
                        </clr-input-container>
                        <clr-password-container>
                            <label class="clr-sr-only" i18n>Password</label>
                            <input type="password" name="password" clrPassword placeholder="Password" [formControl]="password" autocomplete="current-password" />
                        </clr-password-container>
                        <div class="error active" *ngIf="loginError">
                            {{ loginError }}
                        </div>
                        <clr-input-container [hidden]="!twoFactorEntry">
                            <label class="clr-sr-only" i18n>Two-factor auth. code</label>
                            <input type="text" name="twoFactorCode" clrInput #twoFactorCodeElement pattern="[0-9]*" inputmode="numeric" autocomplete="off" placeholder="OTP code" [formControl]="twoFactorCode"/>
                        </clr-input-container>
                        <clr-alert [clrAlertClosable]="false" [hidden]="!twoFactorEntry">
                            <clr-alert-item>
                                <span class="alert-text" i18n>
                                    Please open your two-factor authentication app and type in the one-time code.
                                </span>
                            </clr-alert-item>
                        </clr-alert>
                        <button type="submit" class="btn btn-primary" (click)="login()" *ngIf="!busy" [disabled]="(!email.value || !password.value) && !autofilled" i18n>Login</button>
                        <div *ngIf="busy" class="spinner-block">
                            <clr-spinner clrInline>
                            Authenticating
                            </clr-spinner>
                            <span>
                                Authenticating
                            </span>
                        </div>
                        <a href="javascript:" (click)="register(); $event.stopPropagation()" class="signup" i18n>Registration</a>
                        <a *ngIf="loginError" href="javascript:" (click)="onForgottenPassword(); $event.stopPropagation()" class="signup" i18n>Forgotten Password</a>
                    </div>
                </form>
            </ng-template>

            <p *ngIf="showRecaptchaDisclaimer">This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
        </div>
    </div>
    <div class="logo-wrapper">
        <img class="logo the-logo" alt="Dedicaid" />
    </div>
</div>

<auth-registration #registrationElement></auth-registration>
<auth-forgotten-password #forgottenPasswordPopup></auth-forgotten-password>
<auth-crypto-unavailable></auth-crypto-unavailable>
<auth-unsupported-browser></auth-unsupported-browser>
<auth-muw-disclaimer-popup *ngIf="showMuwDisclaimer" [opened]="true"></auth-muw-disclaimer-popup>

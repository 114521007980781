<clr-modal [(clrModalOpen)]="open" [clrModalSize]="'lg'">
	<h3 class="modal-title" i18n>Two-Factor Backup Codes</h3>
	<div class="modal-body">
		<p i18n>
			The below codes can each be used once instead of the time-based code generated by your application in case you lose access to it.
		</p>
		<p i18n>
			Please keep these codes safe. We strongly suggest printing them.
		</p>
		<pre class="codes">{{ recoveryCodes }}</pre>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline" (click)="open = false" i18n>Close</button>
	</div>
</clr-modal>

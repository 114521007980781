<h1 i18n>Password Recovery</h1>


<form clrForm [formGroup]="passwordForm">
	<p i18n>Please set your new password.</p>
	<clr-password-container>
		<label i18n>New password</label>
		<input clrPassword type="password" formControlName="password" autocomplete="new-password" size="40" tabindex="1" (keyup.enter)="doChangePassword()" />
		<clr-control-error *clrIfError="'minlength'" i18n>The password must have at least 8 characters.</clr-control-error>
		<clr-control-error *clrIfError="'complexity'" i18n>The password must include at least one lower-case and upper-case character, a digit and a symbol.</clr-control-error>
	</clr-password-container>

	<clr-password-container>
		<label i18n>Repeat new password</label>
		<input clrPassword type="password" formControlName="password2" size="40" autocomplete="new-password" tabindex="2" (keyup.enter)="doChangePassword()" />
		<div *ngIf="password2.invalid" class="errors">
			<div *ngIf="password2.errors.noMatch" class="error">Passwords do not match.</div>
		</div>
	</clr-password-container>

	<button class="btn btn-primary" (click)="doChangePassword()" [disabled]="busy" i18n>Change Password</button>
</form>

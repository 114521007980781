export const environment = {
  production: true,
  baseUrl: '/api/v1',
  secureCookie: true,
  enableRadiomics: false,
  enableCdss: true,
  enableCdssEditor: true,
  enableDicom: true,
  enableAutoml: true,
  hideInvoicingAndPayments: false,
  showAutomlQueueStats: false,
  showCdssQueueStats: true,
  enableKillingOwnJobs: false,
  enableEmailLogin: true,
  totpAccountName: "Dedicaid", // Name of service in user's 2FA authenticator app
  cdssSupplierName: "Dedicaid GmbH, Vienna, Austria",
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsSince'
})
export class SecondsSincePipe implements PipeTransform {

  transform(value: Date): number | null {
    if (!value)
      return null;
    return (new Date().getTime() - value.getTime()) / 1000;
  }

}

import { Component, ViewChild } from '@angular/core';
import { AuthenticationService, passwordQualityCheck } from '../../authentication/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ClrForm } from '@clr/angular';

@Component({
  selector: 'app-settings-password',
  templateUrl: './settings-password.component.html',
  styleUrls: ['./settings-password.component.css']
})
export class SettingsPasswordComponent {
  busy: number = 0;
  modalPasswordOpen: boolean = false;
  passwordForm = new FormGroup({
    password1: new FormControl('', [Validators.required, passwordQualityCheck]),
    password2: new FormControl('', Validators.required),
    otpCode: new FormControl('')
  }, {
    validators: [ this.checkPasswordsEqual ]
  });

  @ViewChild(ClrForm)
  private clrForm?: ClrForm;

  constructor(private authenticationService: AuthenticationService) { }

  get usesTwoFactor(): boolean {
    return this.authenticationService.usesTwoFactor;
  }

  public openChangePassword() {
    this.passwordForm.get('password1').setValue('');
    this.passwordForm.get('password2').setValue('');
    this.passwordForm.get('otpCode').setValue('');
    this.passwordForm.get('otpCode').setValidators(this.authenticationService.usesTwoFactor ?
      [Validators.required] : []);
    this.modalPasswordOpen = true;
  }

  checkPasswordsEqual(group: FormGroup<{ password1: FormControl<string>; password2: FormControl<string> }>) {
    let password = group.get('password1').value;
    let password2 = group.get('password2').value;

    if (password === password2)
      return null;
    else
      return { notSame: true };
  }

  savePasswordSettings() {
    console.debug("Match error: " + this.passwordForm.hasError('notSame'));
    if (this.passwordForm.invalid) {
      this.clrForm!.markAsTouched();
      return;
    }

    this.busy++;

    let profileChange = {
      password: this.passwordForm.get('password1').value
    };

    let twoFactorCode;
    if (this.authenticationService.usesTwoFactor)
      twoFactorCode = this.passwordForm.get('otpCode').value;

    this.authenticationService.modifyProfile(this.authenticationService.uid, profileChange, twoFactorCode)
    .pipe(catchError(e => {
      window.alert("Failed to change the password.");
      return of(null);
    }))
    .subscribe(result => {
      if (result)
        this.modalPasswordOpen = false;
      this.busy--;
    });
  }


}

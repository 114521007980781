<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="false">
	<h3 class="modal-title" i18n>Cryptographic Error</h3>
	<div class="modal-body">
		<p i18n>
			This web browser or context doesn't provide required Cryptographic APIs. It is not possible to continue.
		</p>
		<p i18n>
			Please check that you are accessing the web page via <b>HTTPS</b>, and that your browser is up to date.
		</p>
	</div>
</clr-modal>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResultRange } from 'src/common';
import { environment } from 'src/environments/environment';
import { Invoice } from '../vending/vending-backend.service';
import { AuthenticationService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MyInvoicesService {

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  public getMyInvoices(uid: string, offset?: number, count?: number): Observable<ResultRange<Invoice>> {
    let params = new HttpParams();
    if (offset !== undefined)
      params = params.set("from", offset.toFixed(0));

    if (count !== undefined)
      params = params.set("count", count.toFixed(0));
    
    params = params.set("uid", uid);

    return from(this.auth.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.get<Invoice[]>(config.authServiceUrl + '/api/v1/invoice', { params, observe: "response" })
      .pipe(map(resp => {
        let range = resp.headers.get("content-range");
        return {
          totalCount: parseInt(range.split('/')[1]),
          data: resp.body.map(invoice => {
            invoice.when = new Date(invoice.when); // string to Date
            return invoice;
          }),
        };
      }));
    }));
  }

  public downloadInvoicePDF(invoice: Invoice|string): Observable<Blob> {
    let invoiceId: string;
    if (typeof invoice === 'string')
      invoiceId = invoice;
    else
      invoiceId = invoice.id;

    return from(this.auth.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.get(config.authServiceUrl + `/api/v1/invoice/${invoiceId}/pdf`, { responseType: 'blob' });
    }));
  }
}

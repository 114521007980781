<clr-modal [(clrModalOpen)]="modalAccountOpen" [clrModalSize]="'lg'">
	<h3 class="modal-title">Account profile</h3>
	<div class="modal-body">
		<form clrForm [formGroup]="profileForm" *ngIf="!busy">
			<clr-input-container>
				<label>Name</label>
				<input clrInput formControlName="name" size="40" autocomplete="name" />
			</clr-input-container>
			<clr-input-container>
				<label>E-mail</label>
				<input clrInput formControlName="email" size="40" autocomplete="email" />
			</clr-input-container>
			<clr-input-container>
				<label>Telephone</label>
				<input clrInput formControlName="phone" size="40" autocomplete="tel" />
			</clr-input-container>
			<clr-input-container>
				<label>Job title</label>
				<input clrInput formControlName="jobTitle" size="40" autocomplete="organization-title" />
			</clr-input-container>
		</form>

		<div class="spinner-block" *ngIf="busy">
			<span class="spinner spinner-lg">
				Loading...
			</span>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline" (click)="modalAccountOpen = false">Close</button>
		<button type="button" class="btn btn-primary" (click)="saveAccountSettings()" [disabled]="busy">Save</button>
	</div>
</clr-modal>

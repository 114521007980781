
<main-frame-layout>
	<div class="content-area">
		<h1 i18n>Transaction History</h1>

		<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
			<clr-dg-column i18n>Date/Time</clr-dg-column>
			<clr-dg-column i18n>Credit Change</clr-dg-column>
			<clr-dg-column i18n>Operation</clr-dg-column>
			<clr-dg-column i18n>Context</clr-dg-column>

			<ng-template [ngIf]="history">
				<clr-dg-row *ngFor="let h of history.data">
					<clr-dg-cell>{{ h.when | date: "yyyy-MM-dd HH:mm:ss" }}</clr-dg-cell>
					<clr-dg-cell>
						<span [ngClass]="{'tx-credit': h.amount > 0, 'tx-debit': h.amount < 0}">{{ h.amount | transactionAmount }} {{ paymentState?.currency || "" }}</span>
					</clr-dg-cell>
					<clr-dg-cell>
						<div [ngSwitch]="h.operation">
							<span *ngSwitchCase="'credit-topup'" i18n>Credit Top-Up</span>
							<span *ngSwitchCase="'credit-add'" i18n>Free Credit Add</span>
							<span *ngSwitchCase="'order'" i18n>Purchase</span>
							<span *ngSwitchCase="'order-cancel'" i18n>Purchase Cancel</span>
							<span *ngSwitchCase="'credit-refund'" i18n>Credit Charge Refund</span>
						</div>
						
					</clr-dg-cell>
					<clr-dg-cell>
						<span *ngIf="!h.link && h.articleName">{{ h.articleName }}</span>
						<a *ngIf="h.link" [routerLink]="h.link">{{ h.articleName || h.context }}</a>
						<span *ngIf="h.invoiceId !== undefined">
							<button class="btn btn-sm invoice" (click)="downloadInvoicePdf(h.invoiceId)"><cds-icon shape="download"></cds-icon> Invoice</button>
						</span>
					</clr-dg-cell>
				</clr-dg-row>
			</ng-template>

			<clr-dg-footer>
				<clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="history ? history.totalCount : 0">
					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]" i18n>Transactions per page</clr-dg-page-size>
					<span i18n>{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} transactions</span>
				</clr-dg-pagination>
			</clr-dg-footer>
		</clr-datagrid>
	</div>
</main-frame-layout>

export interface AutoMLIntegrityMetadata {
	[featureName: string]: DataIntegrityMetadata;
}

export type DataIntegrityStatus = "LMin" | "GMax" | "OK" | "nan";
export interface DataIntegrityMetadata {
	featureMin: number;
	featureMax: number;
	value: number;
	status: DataIntegrityStatus;
	rank: number;
}

export interface DataIntegrityMetadataWithName extends DataIntegrityMetadata {
	name: string;
}

export function calculateDataIntegrityScore(data: DataIntegrityMetadataWithName[]) {
	let R = data.reduce((acc, feature, index) => {
		const highRanking = index < data.length / 2;

		if (feature.status === "OK")
			return acc+1;
		else if (feature.status === "nan") {
			if (highRanking)
				return acc;
			else
				return acc+0.25;
		} else if (feature.status === "LMin" || feature.status === "GMax") {
			if (highRanking)
				return acc+0.5;
			else
				return acc+0.75;
		}
	}, 0);

	return R / data.length;
}

export function orderDataIntegrityEntries(data: AutoMLIntegrityMetadata): DataIntegrityMetadataWithName[] {
	let rv: DataIntegrityMetadataWithName[] = [];

	Object.keys(data).forEach(featureName => {
		rv.push({
			name: featureName,
			...data[featureName],
		});
	});

	return rv.sort((a, b) => b.rank - a.rank);
}

export function generateDataIntegrityMapTikz(data: DataIntegrityMetadataWithName[]) {
	let tikz = "";

	//tikz += `\\definecolor{IntegrityFeatureOk}{RGB}{${featureOkColor[0]}, ${featureOkColor[1]}, ${featureOkColor[2]}}\n`;
	//tikz += `\\definecolor{IntegrityFeatureProblem}{RGB}{${featureProblemColor[0]}, ${featureProblemColor[1]}, ${featureProblemColor[2]}}\n`;
	//tikz += `\\definecolor{IntegrityFeatureMissing}{RGB}{${featureMissingColor[0]}, ${featureMissingColor[1]}, ${featureMissingColor[2]}}\n`;
	//tikz += `\\definecolor{IntegrityRingOk}{RGB}{${ringOkColor[0]}, ${ringOkColor[1]}, ${ringOkColor[2]}}\n`;
	//tikz += `\\definecolor{IntegrityRingOutOfRange}{RGB}{${ringOutOfRangeColor[0]}, ${ringOutOfRangeColor[1]}, ${ringOutOfRangeColor[2]}}\n\n`;
	//tikz += `\\definecolor{IntegrityRingMissing}{RGB}{${ringMissingColor[0]}, ${ringMissingColor[1]}, ${ringMissingColor[2]}}\n\n`;

	tikz += `\\begin{tikzpicture}\n`;

	tikz += `\\node [draw=textColor, thin, circle, fill=IntegrityRingOutOfRange, minimum size=60mm] (center) {};\n`;
	tikz += `\\node [circle, fill=IntegrityRingOk, minimum size=45mm] (center) {};\n`;
	tikz += `\\node [circle, fill=IntegrityRingOutOfRange, minimum size=30mm] (center) {};\n`;
	tikz += `\\node [circle, fill=IntegrityRingMissing, minimum size=15mm] (center) {};\n`;

	data = data.slice(0, Math.round(data.length / 2));

	data.forEach((mm, index) => {

		let angle = index * 360 / data.length;
		let currentId = `feature${index}`;
		
		let distance: number;
		let color: string;
		let drawColor: string;

		if (mm.status == "OK") {
			color = drawColor = "IntegrityFeatureOk";
			distance = 1.5; // ok circle inner edge
			distance += 0.75 * (mm.value - mm.featureMin) / (mm.featureMax - mm.featureMin);
		} else if (mm.status == "nan") {
			color = "IntegrityRingMissing";
			distance = 0.75/2; // mid red
			drawColor = "IntegrityFeatureMissing";
		} else if (mm.status == "LMin") {
			color = "IntegrityRingOutOfRange";
			distance = 1.5; // red circle edge
			drawColor = "IntegrityFeatureOk";

			let rangeSize = (mm.featureMax - mm.featureMin);
			distance -= 0.75 * (mm.featureMin - mm.value) / rangeSize;

			if (distance < 0.75)
				distance = 0.75;
		} else if (mm.status == "GMax") {
			color = "IntegrityRingOutOfRange";
			distance = 2.25;
			drawColor = "IntegrityFeatureOk";

			let rangeSize = (mm.featureMax - mm.featureMin);
			distance += 0.75 * (mm.value - mm.featureMax) / rangeSize;

			if (distance > 3)
				distance = 3;
		} else {
			console.warn(`Invalid feature status: ${mm.status}`);
			color = "IntegrityFeatureMissing";
			distance = 0.75/2; // center of the green area
		}

		tikz += `\\node[draw=${drawColor}, fill=${color}, minimum size=1.25mm, circle, inner sep=0pt, text width=0.1mm, text height=0.1mm] at ({${angle}}:${distance}) (${currentId}) {};\n`;

		if (index > 0) {
			let adjacentId = `feature${(index-1) % data.length}`;
			tikz += `\\draw[densely dotted] (${currentId})--(${adjacentId});\n`;
		}
	});

	if (data.length > 1)
		tikz += `\\draw[densely dotted] (feature${data.length-1})--(feature0);\n`;

	tikz += `\\end{tikzpicture}\n`;
	return tikz;
}

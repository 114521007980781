import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { ResultRange } from 'src/common';
import { AuthenticationService } from '../authentication/auth.service';
import { Invoice } from '../vending/vending-backend.service';
import * as saveAs from 'file-saver';
import { MyInvoicesService } from './my-invoices.service';

@Component({
  selector: 'app-my-invoices',
  templateUrl: './my-invoices.component.html',
  styleUrls: ['./my-invoices.component.css']
})
export class MyInvoicesComponent {
  loading = true;
  busy = false;
  invoices: ResultRange<Invoice>;

  constructor(private authService: AuthenticationService, private backend: MyInvoicesService) { }

  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;

    let from = state.page.from;
    if (from < 0)
      from = 0;

    this.backend.getMyInvoices(this.authService.uid, from, state.page.size).subscribe(invoices => {
      this.invoices = invoices;
      this.loading = false;
    });
  }

  downloadPdf(invoice: Invoice) {
    this.busy = true;
    this.backend.downloadInvoicePDF(invoice).subscribe(blob => {
      saveAs(blob, invoice.identifier.replace('/', '_') + '.pdf');
      this.busy = false;
    }, err => {
      window.alert($localize `Failed to download PDF.`);
      this.busy = false;
    });
  }

}

<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="!busy">
    <h3 class="modal-title" i18n>Saved Card</h3>
    <div class="modal-body">
		<!--<clr-alert [clrAlertType]="'info'" [clrAlertSizeSmall]="true" [clrAlertClosable]="false">
			<clr-alert-item>
				<span class="alert-text">
					
				</span>
			</clr-alert-item>
		</clr-alert>-->
        <p i18n>
			Your card information is safely stored with our <a href="https://stripe.com/" target="_blank">payments processor</a>.
			Your saved card will only be charged after your account's credit is depleted.
		</p>
		<p *ngIf="!paymentState">
			<span class="spinner spinner-inline">
				Loading...
			</span>
			<span i18n>
				Loading...
			</span>
		</p>
		<div *ngIf="paymentState" class="current-card">
			<div i18n>Current card:</div>
			<span class="saved-card" *ngIf="paymentState.card; else noCard">
				{{ paymentState.card.brand }} xxxx-{{ paymentState.card.last4 }}
				<button class="btn btn-sm btn-danger forget" i18n-title title="Forget" (click)="forgetCard()"><cds-icon shape="trash" size="16"></cds-icon></button>
			</span>
			<ng-template #noCard><span class="no-saved-card" i18n>none</span></ng-template>
		</div>

		<div i18n>
			Save a new card:
				<div id="card-element" #cardElement class="field"></div>
		</div>
	</div>

    <div class="modal-footer">
		<span *ngIf="busy">
			<span class="spinner spinner-inline">
				Busy...
			</span>
			<span i18n>
				Busy...
			</span>
		</span>

		<button type="button" class="btn btn-outline" (click)="opened = false" [disabled]="busy" i18n>Close</button>
		<button type="button" class="btn btn-success" (click)="saveCard()" [disabled]="busy || !paymentState" i18n>Save new card</button>
    </div>
</clr-modal>

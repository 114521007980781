import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../auth.service';

@Component({
  selector: 'app-deletion-after-oauth',
  templateUrl: './deletion-after-oauth.component.html',
  styleUrls: ['./deletion-after-oauth.component.css']
})
export class DeletionAfterOauthComponent {
  private code: string;
  private uid: string;
  private authcode: string;

  busy = false;
  done = false;
  error = false;

  constructor(private route: ActivatedRoute, private auth: AuthenticationService, private title: Title) {
    this.title.setTitle($localize `Account deletion`);

    this.route.queryParams.subscribe(params => {
      this.code = params.code;
      this.uid = params.uid;

      this.doRemoval();
    });
  }

  private doRemoval() {
    
    if (this.uid != this.auth.uid) {
      window.alert($localize `You have logged in as the wrong user.`);
      this.error = true;
      return;
    }

    this.busy = true;

    this.auth.approveDeletion(this.code)
    .subscribe(resp => {
      this.busy = false;
      this.done = true;

      window.alert($localize `Account has now been slated for deletion.`);
    }, err => {
      this.busy = false;
      this.error = true;

      if (err.status === 404) {
        window.alert($localize `This account removal link has already been acted upon and is no longer valid.`);
      } else {
        window.alert(err.error.message || ($localize `Operation has failed`));
      }
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'auth-muw-disclaimer-popup',
  templateUrl: './muw-disclaimer-popup.component.html',
  styleUrls: ['./muw-disclaimer-popup.component.css']
})
export class MuwDisclaimerPopupComponent {
  @Input()
  opened = false;

  constructor() { }

  accept() {
    this.opened = false;
  }

}

// https://dedicaid.atlassian.net/browse/CSF-361
// This code is OK to be present in production builds, because test cards simply don't work in the non-testing environment
export class TestingTokenHelper {
	private cardToken?: string;

	constructor(private window: Window) {}

	public register() {
		const weakthis = new WeakRef(this);
		this.window['setCardTestingToken'] = function(token: string) {
			const self = weakthis.deref();
			if (self)
				self.cardToken = token;
		};
	}

	public get token() {
		return this.cardToken;
	}
}

import { Component, OnInit, ElementRef, ViewChild, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthenticationService, SocialLoginProvider } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RegistrationComponent } from '../registration/registration.component';
import { ForgottenPasswordComponent } from './forgotten-password/forgotten-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginError: string;
  loginErrorMessage: string;
  email = new FormControl('');
  password = new FormControl('');
  twoFactorCode = new FormControl('');
  twoFactorEntry: boolean = false;
  @ViewChild("twoFactorCodeElement") twoFactorElement: ElementRef<HTMLInputElement>
  redirectUrl: string;

  socialLoginProviders: SocialLoginProvider[] = [];
  loadingSocialLoginProviders = false;

  @ViewChild("registrationElement", {static: true}) registrationComponent: RegistrationComponent;

  busy: boolean = false;
  showRecaptchaDisclaimer = false;
  showMuwDisclaimer = !!environment['showMuwStrings'];
  enableEmailLogin = environment.enableEmailLogin;

  @ViewChild('emailField', { static: true })
  emailField: ElementRef<HTMLInputElement>;
  autofilled = false;

  @ViewChild('forgottenPasswordPopup', { static: true })
  forgottenPasswordPopup: ForgottenPasswordComponent;

  constructor(private authenticationService: AuthenticationService, private router: Router, private route: ActivatedRoute, @Inject('Window') private window: Window) {
    
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      try {
        if (this.emailField.nativeElement.matches(":-webkit-autofill"))
          this.autofilled = true;
      } catch (err) {

        try {
          if (this.emailField.nativeElement.matches(":autofill"))
            this.autofilled = true;
        } catch (err) {
        }
      }
    }, 250);
  }

  ngOnInit() {
    this.authenticationService.getBackendConfiguration().subscribe(config => {
      this.showRecaptchaDisclaimer = config.enableRecaptcha;
    });
    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['redirectUrl'];

      this.busy = true;
      if (params['adoptToken']) {
        // This is used by the Report Viewer to pass its token and log in the user in the browser
        this.authenticationService.adoptToken(params['adoptToken']).subscribe(result => {
          this.busy = false;
          if (result.success) {
            this.redirectToTarget();
          } else {
            this.window.alert("Cannot log you in, the token is invalid.");
          }
        });
      } else if (params['loginErrorMessage']) {
        this.loginErrorMessage = params['loginErrorMessage'];
        this.busy = false;
        this.showMuwDisclaimer = false;
      } else if (params['registrationDone']) {
        this.registrationComponent.registrationDone = true;
        this.registrationComponent.opened = true;
      } else {
        // This scenario works out when the user presses Reload while the last token is still valid
        this.authenticationService.reviveCookieToken().subscribe(result => {
          this.busy = false;
          if (result.success) {
            this.redirectToTarget();
          }
        });
      }
    });

    this.loadingSocialLoginProviders = true;
    this.authenticationService.getSocialLoginProviders().subscribe(providers => {
      this.socialLoginProviders = providers;
      this.loadingSocialLoginProviders = false;
    });
  }

  login() {
    this.busy = true;
    this.loginError = null;

    let tfCode;

    if (this.twoFactorEntry)
      tfCode = this.twoFactorCode.value;

    this.authenticationService.authenticate(this.email.value, this.password.value, tfCode)
    .subscribe(result => {
      this.busy = false;

      if (result.success) {
        this.redirectToTarget();
      } else if (result.twoFactorNeeded) {
        this.twoFactorEntry = true;
        window.setTimeout(() => this.twoFactorElement.nativeElement.focus(), 200);
      } else {
        this.loginError = result.message;
      }
    })
  }

  private redirectToTarget() {
    let url = this.redirectUrl || '/';
    this.router.navigateByUrl(url);
  }

  redirectToSocialLogin(provider: SocialLoginProvider) {
    this.authenticationService.getFrontendConfig().then(config => {
      let authUrl = config.authServiceUrl + '/api/v1';

      if (authUrl.startsWith('/') && window.location.hostname === "localhost")
        authUrl = "https://ai-dev.dedicaid.com/auth/api/v1";

      let url = authUrl + provider.url;
      if (this.redirectUrl)
        url += '?redirectUrl=' + encodeURIComponent(this.redirectUrl);

      this.window.location.href = url;
    });
  }

  register() {
    this.registrationComponent.open();
  }

  onForgottenPassword() {
    console.log("onForgottten")
    this.forgottenPasswordPopup.email = this.email.value;
    this.forgottenPasswordPopup.open();
  }

}

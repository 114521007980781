<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="!busy">
	<h3 class="modal-title" i18n>Registration</h3>
	<div class="modal-body">
		<div *ngIf="accountApprovalNeeded">
			<div class="alert alert-warning" role="alert">
				<div class="alert-items">
					<div class="alert-item static">
						<div class="alert-icon-wrapper">
							<cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
						</div>
						<span class="alert-text" i18n>
							Thank you for your interest in our services. Please note that new accounts are currently subject to activation by the administrator.
						</span>
					</div>
				</div>
			</div>
		</div>
		<ng-template [ngIf]="!registrationDone">
			<form clrForm [formGroup]="registrationForm">
				<clr-alert *ngIf="errorMessage" [clrAlertType]="'danger'" [clrAlertClosable]="false">
					<clr-alert-item>
						<span class="alert-text">
							{{ errorMessage }}
						</span>
					</clr-alert-item>
				</clr-alert>

				<clr-input-container>
					<label i18n>E-mail</label>
					<input clrInput email="true" formControlName="email" autocomplete="email" size="40" tabindex="1" />
				</clr-input-container>
				<clr-password-container>
					<label i18n>New password</label>
					<input clrPassword formControlName="password1" size="40" autocomplete="new-password" tabindex="2" />
					<clr-control-error *clrIfError="'minlength'" i18n>The password must have at least 8 characters.</clr-control-error>
					<clr-control-error *clrIfError="'complexity'" i18n>The password must include at least one lower-case and upper-case character, a digit and a symbol.</clr-control-error>
				</clr-password-container>
				<clr-password-container>
					<label i18n>Repeat new password</label>
					<input clrPassword formControlName="password2" size="40" autocomplete="new-password"  tabindex="3" />
				</clr-password-container>
				<clr-alert [clrAlertType]="'warning'" [clrAlertClosable]="false" *ngIf="registrationForm.hasError('notSame')">
					<clr-alert-item i18n>
						Passwords do not match.
					</clr-alert-item>
				</clr-alert>
			</form>
		</ng-template>
		<p *ngIf="registrationDone" i18n>
			Registration has been successful. Please check your inbox to confirm your e-mail address now.
		</p>
	</div>
	<div class="modal-footer">
		<ng-template [ngIf]="!registrationDone">
			<button type="button" class="btn btn-outline" (click)="opened = false" [disabled]="busy" i18n>Cancel</button>
			<button type="button" class="btn btn-primary" (click)="register()" [disabled]="busy" i18n>Register</button>
		</ng-template>

		<ng-template [ngIf]="registrationDone">
			<button type="button" class="btn btn-outline" (click)="opened = false" i18n>Close</button>
		</ng-template>
	</div>
</clr-modal>


	<div class="spinner-wrapper" *ngIf="requesting">
		<clr-spinner></clr-spinner>
	</div>

	<div *ngIf="twoFactorNeeded">
        <form clrForm>
            <h3 i18n>Two-factor authentication needed for this account</h3>
            <div class="login-group">
                <clr-input-container>
                    <label class="clr-sr-only" i18n>Two-factor auth. code</label>
                    <input type="text" name="twoFactorCode" clrInput #twoFactorCodeElement inputmode="numeric" autocomplete="off" placeholder="OTP code" [formControl]="twoFactorCode"/>
                </clr-input-container>
                <clr-alert [clrAlertClosable]="false">
                    <clr-alert-item>
                        <span class="alert-text" i18n>
                            Please open your two-factor authentication app and type in the one-time code.
                        </span>
                    </clr-alert-item>
                </clr-alert>
                <button type="submit" class="btn btn-primary" (click)="doExchange()" *ngIf="!requesting" [disabled]="!twoFactorCode.value" i18n>Finish login</button>
            </div>
        </form>
	</div>


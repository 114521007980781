import { HttpParams } from "@angular/common/http";

export function sharingCodeParams(sharingCode?: string) {
    let params = new HttpParams();

    if (sharingCode)
      params = params.set('sharingCode', sharingCode);

    return params;
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
@Injectable({
  providedIn: 'root'
})
export class DurationPipe implements PipeTransform {

  transform(seconds: number): string {
    var result = "";

    if (seconds >= 60*60*24) { // days
      let num = Math.floor(seconds / (60*60*24));
      result += num.toFixed(0) + $localize `:days:d`;
      seconds -= num*60*60*24;
    }

    if (seconds >= 60*60) { // hours
      let num = Math.floor(seconds / (60*60));

      if (result)
        result += ' ';

      result += num.toFixed(0) + $localize `:hours:h`;
      seconds -= num*60*60;
    }

    if (seconds >= 60) { // minutes
      let num = Math.floor(seconds / 60);

      if (result)
        result += ' ';

      result += num.toFixed(0) + $localize `:minutes:m`;
      seconds -= num*60;
    }

    if (seconds || !result) {
      if (result)
        result += ' ';

      result += seconds.toFixed(0) + $localize `:seconds:s`;
    }
    return result;
  }

}

<clr-modal [(clrModalOpen)]="modalPasswordOpen" [clrModalSize]="'lg'">
	<h3 class="modal-title">Change password</h3>
	<div class="modal-body">
		<form clrForm [formGroup]="passwordForm">
			<clr-password-container>
				<label>New password</label>
				<input clrPassword formControlName="password1" size="40" autocomplete="new-password" />
				<clr-control-error *clrIfError="'minlength'" i18n>The password must have at least 8 characters.</clr-control-error>
				<clr-control-error *clrIfError="'complexity'" i18n>The password must include at least one lower-case and upper-case character, a digit and a symbol.</clr-control-error>
			</clr-password-container>
			<clr-password-container>
				<label>Repeat new password</label>
				<input clrPassword formControlName="password2" size="40" autocomplete="new-password" />
			</clr-password-container>
			<clr-alert [clrAlertType]="'warning'" [clrAlertClosable]="false" *ngIf="passwordForm.hasError('notSame')">
				<clr-alert-item>
					Passwords do not match.
				</clr-alert-item>
			</clr-alert>
			<clr-input-container *ngIf="usesTwoFactor">
				<label>Two-factor authentication code</label>
				<input clrInput pattern="[0-9]*" inputmode="numeric" autocomplete="off" formControlName="otpCode" size="40" />
				<clr-control-error>Please open your two-factor authentication app and type in the one-time code.</clr-control-error>
			</clr-input-container>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline" (click)="modalPasswordOpen = false">Close</button>
		<button type="button" class="btn btn-primary" (click)="savePasswordSettings()" [disabled]="busy">Save</button>
	</div>
</clr-modal>

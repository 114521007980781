import { KernelDensityResult, ValueDistribution } from "./performance";

export class KernelDensity {
	public static estimate(valuesIn: number[], valuesArrayLen?: number): KernelDensityResult {
		const distribution = KernelDensity.analyzeValueDistribution(valuesIn);

		let labelValues = Array.from(valuesIn).filter(v => !isNaN(v) && isFinite(v) && v !== null).sort();
		let maxDensity;

		const min = distribution.min;
		const max = distribution.max;
		const range = max - min;

		if (valuesArrayLen === undefined)
			valuesArrayLen = 2000;

		// FIXME: Magic value that makes the graph look nice instead of very dull and flat
		let stdDeviation = distribution.stdDeviation / 4;

		let values: number[];
		if (min !== max) {

			values = Array(valuesArrayLen).fill(0);
			labelValues.forEach(value => {
				let from = Math.round((value - 3*stdDeviation - min) / range * values.length);
				let to = Math.round((value + 3*stdDeviation - min) / range * values.length);

				if (from < 0)
					from = 0;
				if (to >= values.length)
					to = values.length - 1;

				for (let i = from; i <= to; i++) {
					const mi = value;
					const x = (i / values.length) * range + min;

					values[i] += (1 / (stdDeviation * Math.sqrt(2*Math.PI))) * Math.exp(-0.5 * ( ((x-mi)*(x-mi)) / (stdDeviation * stdDeviation) ));
				}
			});

			maxDensity = values.reduce((max, cur) => {
				return Math.max(max, cur);
			}, 0);

			// Normalize to 0...1
			values = values.map(v => v / maxDensity);
		}

		return {
			density: values,
			min,
			max,
			mean: distribution.mean,
			stdDeviation: distribution.stdDeviation,
			median: distribution.median,
			lowerQuartile: distribution.lowerQuartile,
			upperQuartile: distribution.upperQuartile,
			meanDev: distribution.meanDev,
			ci95From: distribution.ci95From,
			ci95To: distribution.ci95To,
		};
	}

	public static medianValue(data: number[]) {
		if (data.length % 2 === 1)
		  return data[(data.length - 1) / 2];
		else {
		  return (data[data.length / 2] + data[data.length / 2 - 1]) / 2;
		}
	}

	public static analyzeValueDistribution(valuesIn: number[]): ValueDistribution {
		let labelValues = Array.from(valuesIn).filter(v => !isNaN(v) && isFinite(v) && v !== null).sort();
    	let stdDeviation = 0, mean = 0;

		const min = labelValues[0];
		const max = labelValues[labelValues.length - 1];

		labelValues.forEach(value => {
			mean += value / labelValues.length;
		});

		labelValues.forEach(value => {
		let diff = mean - value;
			stdDeviation += (diff * diff) / labelValues.length;
		});

		stdDeviation = Math.sqrt(stdDeviation); // stdDev = sqrt(variance)

		let median = KernelDensity.medianValue(labelValues);
		let lowerQuartile, upperQuartile;

		if (labelValues.length > 1) {
			lowerQuartile = KernelDensity.medianValue(labelValues.slice(0, Math.ceil(labelValues.length / 2)));
			upperQuartile = KernelDensity.medianValue(labelValues.slice(Math.ceil(labelValues.length / 2), labelValues.length));
		} else {
			lowerQuartile = median;
			upperQuartile = median;
		}

		let meanDev = labelValues.reduce((acc, val) => {
			return acc + Math.abs(mean - val) / labelValues.length;
		}, 0);

		// https://www.dummies.com/article/academics-the-arts/math/statistics/how-to-calculate-a-confidence-interval-for-a-population-mean-when-you-know-its-standard-deviation-169722
		const zValue = 1.96; // z*-value for 95% CI
		let errorMargin = (zValue * stdDeviation) / Math.sqrt(labelValues.length);
		let ci95From = Math.max(0, mean - errorMargin);
		let ci95To = Math.min(1, mean + errorMargin);

		return {
			min,
			max,
			mean,
			stdDeviation,
			median,
			lowerQuartile,
			upperQuartile,
			meanDev,
			ci95From,
			ci95To,
		};
	}
}



<clr-modal [(clrModalOpen)]="opened" [clrModalSize]="'lg'">
	<h3 class="modal-title" i18n>Institution settings</h3>
	<div class="modal-body">
		<form clrForm [formGroup]="form">
			<clr-input-container>
				<label i18n>Institution Name</label>
				<input clrInput formControlName="institutionName" size="40" autocomplete="organization" />
				<clr-control-helper i18n>If filled in, this text will override the institution name provided in DICOM files.</clr-control-helper>
			</clr-input-container>

			<clr-input-container>
				<label i18n>Institution Logo</label>
				<input clrInput type="file" accept=".jpg, .png, .jpeg" #institutionLogo />
				<clr-control-helper i18n>The logo must be smaller than 1 MB.</clr-control-helper>
			</clr-input-container>

			<div class="clr-form-control clr-row" *ngIf="currentLogo">
				<label class="clr-control-label" i18n>Current Institution Logo</label>
				<div class="clr-control-container">
					<img [src]="currentLogo" class="current-logo" alt="Current Logo" />
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline" (click)="opened = false" i18n>Close</button>
		<button type="button" class="btn btn-primary" (click)="save()" [disabled]="busy" i18n>Save</button>
	</div>
</clr-modal>

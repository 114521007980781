import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../authentication/auth.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { RecoveryCodesComponent } from './recovery-codes/recovery-codes.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-two-factor',
  templateUrl: './settings-two-factor.component.html',
  styleUrls: ['./settings-two-factor.component.css']
})
export class SettingsTwoFactorComponent {
  modal2FAOpen: boolean = false;
  busy: number = 0;
  recoveryCodes: string;
  error = false;

  twoFactorSecret: string;
  twoFactorForm = new FormGroup({
    twoFactorActivationCode: new FormControl('', [Validators.required])
  });
  @ViewChild("twoFactorActivationCode") twoFactorActivationCodeElement: ElementRef<HTMLInputElement>;

  @ViewChild("recoveryCodesPopup")
  recoveryCodesPopup: RecoveryCodesComponent;

  totpAccountName: string;

  constructor(private authenticationService: AuthenticationService) { }

  get usesTwoFactor(): boolean {
    return this.authenticationService.usesTwoFactor;
  }

  open2FASettings() {
    this.twoFactorSecret = undefined;
    this.totpAccountName = undefined;
    this.error = false;
    this.twoFactorForm.get('twoFactorActivationCode').setValue('');

    // Reload the 2FA state...
    this.authenticationService.get2FAState(this.authenticationService.uid)
    .subscribe(enabled => {
      this.authenticationService.usesTwoFactor = enabled;
      this.modal2FAOpen = true;
    });
  }

  enroll2FA() {
    this.busy++;
    this.authenticationService.set2FAState(this.authenticationService.uid, true)
    .subscribe({
      next: secret => {
        this.error = false;
        this.twoFactorSecret = secret as string;

        this.authenticationService.getProfile(this.authenticationService.uid).subscribe(profile => {
          this.totpAccountName = encodeURIComponent(`${environment.totpAccountName} (${profile.email})`);
          window.setTimeout(() => this.focusTwoFactorActivationCode(), 250);
          this.busy--;
        });
      },
      error: err => {
        this.error = true;
        this.busy--;
      }
    });
  }

  private focusTwoFactorActivationCode() {
    if (this.twoFactorActivationCodeElement.nativeElement)
      this.twoFactorActivationCodeElement.nativeElement.focus();
  }

  confirm2FA() {
    if (this.twoFactorForm.invalid) {
      this.twoFactorForm.markAsTouched();
      return;
    }

    this.busy++;

    let code = this.twoFactorForm.get('twoFactorActivationCode').value;
    this.authenticationService.confirm2FAState(this.authenticationService.uid, code)
    .subscribe({
      next: res => {
        this.error = false;
        this.busy--;

        this.error = false;
        this.recoveryCodes = res.recoveryCodes.join('\n');
        this.authenticationService.usesTwoFactor = true;
        this.modal2FAOpen = false;
        this.recoveryCodesPopup.open = true;
      },
      error: err => {
        this.error = true;
        this.busy--;
      }
    });
  }

  disable2FA() {
    if (this.twoFactorForm.invalid) {
      this.twoFactorForm.markAsTouched();
      return;
    }

    this.busy++;

    let code = this.twoFactorForm.get('twoFactorActivationCode').value;
    this.authenticationService.set2FAState(this.authenticationService.uid, false, code)
    .subscribe({
      next: res => {
        this.error = false;

        if (res === false) {
          this.authenticationService.usesTwoFactor = true;
          this.modal2FAOpen = false;
        }
      },
      error: err => {
        this.error = true;
        this.busy--;
      },
    });
  }


}

<main-frame-layout>
	<div class="content-area">
		<h1 i18n>My Invoices</h1>
		<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
			<clr-dg-column i18n>Identifier</clr-dg-column>
			<clr-dg-column i18n>Date / Time</clr-dg-column>
			<clr-dg-column i18n>Amount</clr-dg-column>
			<clr-dg-column i18n>Actions</clr-dg-column>

			<ng-template [ngIf]="invoices">
				<clr-dg-row *ngFor="let invoice of invoices.data">
					<clr-dg-cell>{{ invoice.identifier }}</clr-dg-cell>
					<clr-dg-cell>{{ invoice.when | date }}</clr-dg-cell>
					<clr-dg-cell>{{ invoice.total / 100 }} {{ invoice.currency }}</clr-dg-cell>
					<clr-dg-cell><button class="btn btn-sm" (click)="downloadPdf(invoice)" [disabled]="busy" i18n>Download PDF</button></clr-dg-cell>
				</clr-dg-row>
			</ng-template>

			<clr-dg-footer>
				<clr-dg-pagination #pagination [clrDgPageSize]="10" [clrDgTotalItems]="invoices ? invoices.totalCount : 0">
					<clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]" i18n>Items per page</clr-dg-page-size>
					<span i18n>{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} invoices</span>
				</clr-dg-pagination>
			</clr-dg-footer>
		</clr-datagrid>
	</div>
</main-frame-layout>

import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { isSupportedUserAgent } from '../user-agent';

@Component({
  selector: 'auth-unsupported-browser',
  templateUrl: './unsupported-browser.component.html',
  styleUrls: ['./unsupported-browser.component.css']
})
export class UnsupportedBrowserComponent implements OnInit {
  opened = false;

  // Abstracted to allow for unit testing
  constructor(private renderer: Renderer2, @Inject('Window') private selfRef: typeof self) { }

  ngOnInit(): void {
    this.opened = !isSupportedUserAgent(this.renderer, this.selfRef);
  }

}

<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="false">
    <h3 class="modal-title" i18n>Unsupported web browser</h3>
    <div class="modal-body">
        <p i18n>You are using an unsupported web browser or your current browser settings disable some of the required functionality.</p>
        <p i18n>Please make sure you're using a browser that supports all of the below features:</p>
        <ul>
            <li>WebAssembly</li>
            <li>WebGL version 2</li>
            <li>OffscreenCanvas</li>
        </ul>
    </div>
</clr-modal>

import * as base64 from 'base64-arraybuffer';

// Shared implementation for web and Node.js
// web: pass self.crypto as the Crypto implementation
// Node.js: pass crypto.webcrypto

export const asymKeyParams = {
	name: "RSA-OAEP",
	hash: "SHA-256",
  };
  
export const symKeyParams: AesKeyGenParams = {
	name: "AES-CBC",
	length: 256,
};
  
function appendBuffers(...bufs: ArrayBuffer[]) {

	const totalLen = bufs.reduce((acc, cur) => acc + cur.byteLength, 0);
	let tmp = new Uint8Array(totalLen);
	let prevTotalLen = 0;

	for (let i = 0; i < bufs.length; i++) {
		tmp.set(new Uint8Array(bufs[i]), prevTotalLen);
		prevTotalLen += bufs[i].byteLength;
	}

	return tmp.buffer;
};

export function importUsersPublicKey(crypto: Crypto, publicKeyString: string): Promise<CryptoKey> {
	return crypto.subtle.importKey("spki", base64.decode(publicKeyString), asymKeyParams, true, ["encrypt"]);
}

export function importUsersPrivateKey(crypto: Crypto, privateKeyString: string): Promise<CryptoKey> {
	return crypto.subtle.importKey("pkcs8", base64.decode(privateKeyString), asymKeyParams, false, ["decrypt"]);
}

// Output:
// base64enc( RSA-OAEP( aesKey || aesIV ) || AES-CBC( inputString ) )
export async function encryptString(crypto: Crypto, str: string, publicKey: CryptoKey): Promise<string> {
	let encoder = new TextEncoder();
	const symKey = <CryptoKey> await crypto.subtle.generateKey(symKeyParams, true, ["encrypt"]);
	const symKeyExported = await crypto.subtle.exportKey("raw", symKey);
  
	const iv = crypto.getRandomValues(new Uint8Array(16));
	const encryptedSymKeyAndIV = await crypto.subtle.encrypt(asymKeyParams, publicKey, appendBuffers(symKeyExported, iv));
  
	const encryptedInputText = await crypto.subtle.encrypt({
	  name: "AES-CBC",
	  iv,
	}, symKey, encoder.encode(str));
  
	let all = appendBuffers(encryptedSymKeyAndIV, encryptedInputText);
	return base64.encode(all);
  }
  
export async function decryptString(crypto: Crypto, str: string, privateKey: CryptoKey): Promise<string> {
	const cipheredMessage = base64.decode(str);
	const keyLength = (<RsaHashedKeyGenParams>privateKey.algorithm).modulusLength / 8;

	const encryptedSymKeyAndIV = cipheredMessage.slice(0, keyLength);
	const encryptedString = cipheredMessage.slice(keyLength);

	const symKeyAndIV = await crypto.subtle.decrypt(asymKeyParams, privateKey, encryptedSymKeyAndIV);
	const symKey = await crypto.subtle.importKey("raw", symKeyAndIV.slice(0, symKeyParams.length / 8), symKeyParams, false, ["decrypt"]);

	let data = await crypto.subtle.decrypt({
		name: "AES-CBC",
		iv: symKeyAndIV.slice(symKeyParams.length / 8),
	}, symKey, encryptedString);

	let decoder = new TextDecoder();
	return decoder.decode(data);
}

export async function extractSymmetricKey(crypto: Crypto, encryptedString: string, privateKey: CryptoKey): Promise<string> {
	const cipheredMessage = base64.decode(encryptedString);
	const keyLength = (<RsaHashedKeyGenParams>privateKey.algorithm).modulusLength / 8;

	const encryptedSymKeyAndIV = cipheredMessage.slice(0, keyLength);
	const symKeyAndIV: ArrayBuffer = await crypto.subtle.decrypt(asymKeyParams, privateKey, encryptedSymKeyAndIV);

	return base64.encode(symKeyAndIV);
}

export async function decryptStringWithSymmetricKey(crypto: Crypto, str: string, symmetricKey: string): Promise<string> {
	const cipheredMessage = base64.decode(str);
	const keyLength = 4096 / 8;

	const encryptedString = cipheredMessage.slice(keyLength);

	const symKeyAndIV = base64.decode(symmetricKey);
	const symKey = await crypto.subtle.importKey("raw", symKeyAndIV.slice(0, symKeyParams.length / 8), symKeyParams, false, ["decrypt"]);

	let data = await crypto.subtle.decrypt({
		name: "AES-CBC",
		iv: symKeyAndIV.slice(symKeyParams.length / 8),
	}, symKey, encryptedString);

	let decoder = new TextDecoder();
	return decoder.decode(data);
}

import { Renderer2 } from "@angular/core";

export function isSupportedUserAgent(renderer: Renderer2, selfRef: typeof self) {
	return isOffscreenCanvasSupported(renderer) && isWasmSupported(selfRef) && isWebGL2Supported(renderer, selfRef);
}

// This is the most problematic feature we use _and_ don't have a fallback for
export function isOffscreenCanvasSupported(renderer: Renderer2) {
	try {
		const canvas = renderer.createElement('canvas');
		return !!canvas.transferControlToOffscreen();
	} catch (e) {
		return false;
	}
}

// Wasm can be explicitly disabled in browser's about:config
export function isWasmSupported(selfRef: typeof self) {
    try {
        if (typeof selfRef.WebAssembly === "object"
            && typeof selfRef.WebAssembly.instantiate === "function") {
            const module = new selfRef.WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module instanceof selfRef.WebAssembly.Module)
                return new selfRef.WebAssembly.Instance(module) instanceof selfRef.WebAssembly.Instance;
        }
    } catch (e) {
    }
    return false;
}

// WebGL can be explicitly disabled in browser's about:config
export function isWebGL2Supported(renderer: Renderer2, selfRef: typeof self) {
	try {
		const canvas = renderer.createElement('canvas');
		return !!selfRef.WebGL2RenderingContext && canvas.getContext('webgl2');
	} catch(e) {
		return false;
	}
}

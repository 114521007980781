import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ResultRange } from 'src/common';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication/auth.service';
import { UserTransaction } from './user-management/user.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _changeSubject = new Subject<void>();

  constructor(private authService: AuthenticationService, private http: HttpClient) { }

  getPaymentState(): Observable<PaymentState> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.get<PaymentState>(config.authServiceUrl + `/api/v1/user/${this.authService.uid}/payment`);
    }));
  }

  initiateSavedCardSetup(): Observable<CardSetupData> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.post<CardSetupData>(config.authServiceUrl + `/api/v1/user/${this.authService.uid}/payment/initiate-setup`, '');
    }));
  }

  completeSavedCardSetup(cardId: string): Observable<void> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.post<void>(config.authServiceUrl + `/api/v1/user/${this.authService.uid}/payment/complete-setup`, { cardId })
        .pipe(tap(() => {
          this._changeSubject.next();
      }));
    }));
  }

  deleteSavedCard(): Observable<void> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.delete<void>(config.authServiceUrl + `/api/v1/user/${this.authService.uid}/payment`).pipe(tap(() => {
        this._changeSubject.next();
      }));
    }));
  }

  initiateTopUp(amount: number): Observable<CardChargeData> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.post<CardChargeData>(config.authServiceUrl + `/api/v1/user/${this.authService.uid}/payment/initiate-topup`, {
        amount
      });
    }));
  }

  completeTopUp(data: CardChargeData): Observable<void> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.get<void>(config.authServiceUrl + `/api/v1/user/${this.authService.uid}/payment/complete-topup/${data.provider}/${data.transactionId}`);
    }));
  }

  topUpFromSavedCard(amount: number): Observable<void> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.post<void>(config.authServiceUrl + `/api/v1/user/${this.authService.uid}/payment/topup-saved`, {
        amount
      });
    }));
  }

  public getTransactionHistory(userId: string, offset: number, count: number): Observable<ResultRange<UserTransaction>> {
    let params = new HttpParams();
    if (offset !== undefined)
      params = params.set("from", offset.toFixed(0));

    if (count !== undefined)
      params = params.set("count", count.toFixed(0));
    
      return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
        return this.http.get<UserTransaction[]>(config.authServiceUrl + '/api/v1/user/' + userId + '/payment/history', { params, observe: "response" })
        .pipe(map(resp => {
          let range = resp.headers.get("content-range");
          return {
            totalCount: parseInt(range.split('/')[1]),
            data: resp.body.map(h => {
              h.when = new Date(h.when);
              return h;
            }),
          };
        }));
      }));
  }

  public get changeSubject(): Subject<void> {
    return this._changeSubject;
  }

  public getProviderConfig(providerName: string): Observable<object> {
    return from(this.authService.getFrontendConfig()).pipe(switchMap(config => {
      return this.http.get<object>(`${config.authServiceUrl}/api/v1/payment/config/${providerName}`);
    }));
  }
}

export interface SavedCard {
  brand: string;
  last4: string;
}

export interface CardChargeData {
	provider: string;
	transactionId: string;
}

export interface CardSetupData {
	provider: string;
}

export interface StripeCardChargeData extends CardChargeData {
	client_secret: string;
}

export interface StripeCardSetupData extends CardSetupData {
	client_secret: string;
}


export interface PaymentState {
  credit: number;
  provider: string;
  card?: SavedCard;
  currency: string;
  priceList?: string;
}

// Body contents of HTTP 402 Payment Required
export interface PaymentRequiredError {
  amount: number;
  currency: string;
}


<h1 i18n>Account Deletion</h1>

<ng-template [ngIf]="!error && !done">

    <p i18n>
        Please approve or cancel the account deletion. Take note that account deletion is non-reversible.
    </p>

    <p i18n>
        Account deletion requires that you confirm your credentials and also your Two-Factor Authentication code (if enabled).
    </p>

    <div *ngIf="busy" class="spinner">
        <img src="assets/spinner.gif" width="96" height="96" alt="" /><span i18n>Loading...</span>
    </div>

    <div *ngIf="!done && !showPasswordBasedLogin">
        <button class="btn btn-outline" (click)="doCancel()" [disabled]="busy" i18n>Cancel Deletion</button>

        <button class="btn btn-primary" (click)="doOauth()" [disabled]="busy" i18n>Sign in to Approve Deletion</button>
    </div>

    <div [style.visibility]="showPasswordBasedLogin ? 'visible' : 'hidden'">
        <hr />
        <form clrForm [formGroup]="loginForm">
            <p i18n>Authenticate now to approve deletion.</p>
            <div class="form-field">
                <label i18n>E-mail</label>
                <input email="true" formControlName="email" autocomplete="email" size="40" tabindex="1" />
            </div>
    
            <div class="form-field">
                <label i18n>Password</label>
                <input type="password" formControlName="password" size="40" autocomplete="password" tabindex="2" />
            </div>

            <div class="form-field" [hidden]="!twoFactorAuthenticationNeeded">
                <label i18n>Two-Factor Authentication Code</label>
                <input type="string" formControlName="tfCode" size="40" tabindex="3" />
            </div>

            <button class="btn btn-outline" (click)="doCancel()" [disabled]="busy" i18n>Cancel Deletion</button>
            <button class="btn btn-danger" (click)="doApprove()" [disabled]="busy" i18n>Confirm Deletion</button>
        </form>
    </div>
</ng-template>

<div class="error" *ngIf="error" i18n>
    Account removal link is not valid.
</div>

<div *ngIf="done" i18n>You may now close this window</div>

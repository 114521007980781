import { Component, OnInit, ViewChild } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService, passwordQualityCheck } from '../auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ClrForm } from '@clr/angular';

@Component({
  selector: 'auth-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  registrationForm = new FormGroup({
    password1: new FormControl('', [Validators.required, passwordQualityCheck]),
    password2: new FormControl('', Validators.required),
    email: new FormControl('', [ Validators.required, Validators.email ])
  }, {
    validators: [ this.checkPasswordsEqual ]
  });

  opened = false;
  busy = false;
  registrationDone = false;
  errorMessage: string;

  accountApprovalNeeded = false;
  enableRecaptcha = true;

  @ViewChild(ClrForm)
  private clrForm?: ClrForm;

  constructor(private recaptcha: ReCaptchaV3Service, private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.authenticationService.getBackendConfiguration().subscribe(config => {
      this.accountApprovalNeeded = config.accountApprovalNeeded;
      this.enableRecaptcha = config.enableRecaptcha;
    });
  }

  open() {
    this.opened = true;
  }

  checkPasswordsEqual(group: FormGroup<{ password1: FormControl<string>; password2: FormControl<string> }>) {
    let password = group.get('password1').value;
    let password2 = group.get('password2').value;

    if (password === password2)
      return null;
    else
      return { notSame: true };
  }

  register() {
    if (this.registrationForm.invalid) {
      this.clrForm?.markAsTouched();
      return;
    }

    this.busy = true;

    if (this.enableRecaptcha) {
      this.recaptcha.execute("register").subscribe(token => {
        this.doRegister(token);
      });
    } else {
      this.doRegister(null);
    }
  }

  private doRegister(antiBotToken: string) {
    this.authenticationService.registerUser(
      this.registrationForm.get('email').value,
      this.registrationForm.get('password1').value, antiBotToken).subscribe(() => {

        this.registrationDone = true;
        this.busy = false;
      }, (error: HttpErrorResponse) => {
        this.busy = false;
        let errorMessage = error.message;

        if (typeof error.error === 'object' && error.error.message)
          errorMessage = error.error.message;

        this.errorMessage = errorMessage;
      });
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AfterOauthLoginComponent } from './after-oauth-login/after-oauth-login.component';
import { AccountActivateComponent } from './account-activate/account-activate.component';
import { AccountRejectComponent } from './account-reject/account-reject.component';
import { DeviceLoginDoneComponent } from './device-login-done/device-login-done.component';
import { AccountUnapprovedComponent } from './account-unapproved/account-unapproved.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { DeletionComponent } from './deletion/deletion.component';
import { DeletionAfterOauthComponent } from './deletion-after-oauth/deletion-after-oauth.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'unapproved', component: AccountUnapprovedComponent },
  { path: 'after-oauth', component: AfterOauthLoginComponent },
  { path: 'confirm', component: AccountActivateComponent },
  { path: 'reject', component: AccountRejectComponent },
  { path: 'device-login-done', component: DeviceLoginDoneComponent },
  { path: 'password-recovery', component: PasswordRecoveryComponent },
  { path: 'deletion', component: DeletionComponent },
  { path: 'deletion-after-oauth', component: DeletionAfterOauthComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-recovery-codes',
  templateUrl: './recovery-codes.component.html',
  styleUrls: ['./recovery-codes.component.css']
})
export class RecoveryCodesComponent {
  @Input()
  recoveryCodes: string;

  open = false;

  constructor() { }

}

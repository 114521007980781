import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { SettingsAccountComponent } from './settings-account/settings-account.component';
import { SettingsTwoFactorComponent } from './settings-two-factor/settings-two-factor.component';
import { SettingsPasswordComponent } from './settings-password/settings-password.component';
import { SettingsInvoicingComponent } from './settings-invoicing/settings-invoicing.component';
import { QrCodeModule } from 'ng-qrcode';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccountRemovalComponent } from './account-removal/account-removal.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { SettingsSavedCardComponent } from './settings-saved-card/settings-saved-card.component';
import { TopupCreditComponent } from './topup-credit/topup-credit.component';
import { EditableTextComponent } from './editable-text/editable-text.component';
import { RecoveryCodesComponent } from './settings-two-factor/recovery-codes/recovery-codes.component';
import { SettingsInstitutionComponent } from './settings-institution/settings-institution.component';
import { ReorderableListDirective } from './reorderable/reorderable-list.directive';
import { ReorderableHandleDirective } from './reorderable/reorderable-handle.directive';
import { ReorderableItemDirective } from './reorderable/reorderable-item.directive';
import '@cds/core/icon/register.js';
import { angleIcon, ClarityIcons, trashIcon, userIcon } from '@cds/core/icon';

ClarityIcons.addIcons(userIcon, angleIcon, trashIcon);

@NgModule({
  declarations: [
    LayoutComponent,
    SettingsAccountComponent,
    SettingsTwoFactorComponent,
    SettingsPasswordComponent,
    SettingsInvoicingComponent,
    AccountRemovalComponent,
    MessageBoxComponent,
    SettingsSavedCardComponent,
    TopupCreditComponent,
    EditableTextComponent,
    RecoveryCodesComponent,
    SettingsInstitutionComponent,
    ReorderableListDirective,
    ReorderableHandleDirective,
    ReorderableItemDirective,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    QrCodeModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    LayoutComponent,
    EditableTextComponent,
    ReorderableListDirective,
    ReorderableItemDirective,
    ReorderableHandleDirective,
  ]
})
export class MainFrameModule { }

<div class="content">
	<h1>Account Removal</h1>

	<ng-template [ngIf]="!accountDeleted && !error">
		<p>Do you really wish to delete your unactivated account?</p>

		<p>
			<button class="btn btn-danger" (click)="deleteAccount()" [disabled]="busy">Delete</button>
		</p>
	</ng-template>

	<ng-template [ngIf]="error">
		<clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
			<clr-alert-item>
				<span class="alert-text">
					{{ error }}
				</span>
			</clr-alert-item>
		</clr-alert>
	</ng-template>

	<ng-template [ngIf]="accountDeleted">
		<clr-alert [clrAlertType]="'auccess'" [clrAlertClosable]="false">
			<clr-alert-item>
				<span class="alert-text">
					The account has been deleted.
				</span>
			</clr-alert-item>
		</clr-alert>
	</ng-template>

</div>

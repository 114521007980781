import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AutoMLJobStats } from '../auto-ml/auto-mlbackend.service';
import { PaymentService, PaymentState } from '../payment.service';
import { AuthenticationService } from '../authentication/auth.service';
import { CdssJobStats } from 'cdss-common-lib';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  paymentState: PaymentState;
  private paymentChangeSubscription: Subscription;

  automlStats?: AutoMLJobStats;
  cdssStats?: CdssJobStats;

  showMuwOnlyAcknowledgement = !!environment['showMuwStrings'];

  constructor(private paymentService: PaymentService, private router: Router, private http: HttpClient, private authService: AuthenticationService) { }

  ngOnDestroy(): void {
    if (this.paymentChangeSubscription)
      this.paymentChangeSubscription.unsubscribe();
  }

  ngOnInit() {
    if (!environment.hideInvoicingAndPayments) {
      this.loadPaymentState();

      this.paymentChangeSubscription = this.paymentService.changeSubject.subscribe(() => {
        this.loadPaymentState();
      });
    }

    if (this.authService.hasRole('root') || environment.showAutomlQueueStats) {
      this.loadAutomlQueueStats();
    }
    if (environment.showCdssQueueStats) {
      this.loadCdssQueueStats();
    }
  }

  loadPaymentState() {
    this.paymentService.getPaymentState().subscribe(paymentState => {
      this.paymentState = paymentState;
    });
  }

  private loadAutomlQueueStats() {
    this.http.get<AutoMLJobStats>(environment.baseUrl + '/automl-submission/stats').subscribe(stats => {
      this.automlStats = stats;
    });
  }

  private loadCdssQueueStats() {
    this.http.get<CdssJobStats>(environment.baseUrl + '/cdss/submission/stats').subscribe(stats => {
      this.cdssStats = stats;
    });
  }

  showTransactions() {
    this.router.navigateByUrl("/dashboard/transactions");
  }

}


import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/auth.service';
import { MyInvoicesService } from 'src/app/my-invoices/my-invoices.service';
import { UserTransaction } from 'src/app/user-management/user.service';
import { ResultRange } from 'src/common';
import * as saveAs from 'file-saver';
import { PaymentService, PaymentState } from '../../payment.service';

const reAutomlReport = /item\/automl\/(\d+)\/build/;
const reAutomlModelAPIAccess = /item\/automl\/(\d+)\/run/;
const reAutomlModelValidation = /item\/automl\/(\d+)\/validation/;
const reAutomlModelOffline = /item\/automl\/(\d+)\/offline/;

@Component({
  selector: 'app-dashboard-transactions',
  templateUrl: './dashboard-transactions.component.html',
  styleUrls: ['./dashboard-transactions.component.css']
})
export class DashboardTransactionsComponent implements OnInit, OnDestroy {
  paymentState: PaymentState;
  private paymentChangeSubscription: Subscription;
  private lastDatagridState: ClrDatagridStateInterface;
  loading = false;

  history: ResultRange<UserTransactionWithLink>;

  constructor(private paymentService: PaymentService, private authService: AuthenticationService, private invoicesService: MyInvoicesService) { }

  ngOnDestroy(): void {
    if (this.paymentChangeSubscription)
      this.paymentChangeSubscription.unsubscribe();
  }

  ngOnInit() {
    this.loadPaymentState();

    this.paymentChangeSubscription = this.paymentService.changeSubject.subscribe(() => {
      this.loadPaymentState();
    });
  }

  loadPaymentState() {
    this.paymentService.getPaymentState().subscribe(paymentState => {
      this.paymentState = paymentState;
    });
  }

  refresh(state: ClrDatagridStateInterface) {
    this.lastDatagridState = state;
    this.loading = true;

    let from = state.page.from;
    if (from < 0)
      from = 0;
    
    this.paymentService.getTransactionHistory(this.authService.uid, from, state.page.size).subscribe(history => {
      history.data.forEach(item => {
        let itemWithLink = <UserTransactionWithLink>item;

        let match = reAutomlReport.exec(item.context);
        if (match) {
          itemWithLink.link = `/automl/report/${match[1]}`;
          return;
        }

        match = reAutomlModelAPIAccess.exec(item.context);
        if (match) {
          itemWithLink.link = `/automl/report/${match[1]}/api`;
          return;
        }

        match = reAutomlModelValidation.exec(item.context);
        if (match) {
          itemWithLink.link = `/automl/report/${match[1]}/validate`;
          return;
        }

        match = reAutomlModelOffline.exec(item.context);
        if (match) {
          itemWithLink.link = `/automl/report/${match[1]}/offline-model`;
          return;
        }
      });
      this.history = history;
      this.loading = false;
    });
  }

  downloadInvoicePdf(invoiceId: string) {
    this.invoicesService.downloadInvoicePDF(invoiceId).subscribe(blob => {
      saveAs(blob, 'invoice.pdf');
    }, err => {
      window.alert($localize `Failed to download PDF.`);
    });
  }
}

interface UserTransactionWithLink extends UserTransaction {
  link?: string;
}

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AutoMLPublicPerformance, CdssBodyRegion, CdssModel, ModelListItem, SuitableAutoMLModel } from './submission/model';
import { DLPipeline } from './dl-pipelines';
import { GeneratedFeatureNamesProvider, GenericNamedProject, SampleFeature } from './radiomics';
import { sharingCodeParams } from './sharing';
import { ResultRange } from './common';
import { BASE_URL } from './dicom.service';

@Injectable({
  providedIn: 'root'
})
export class ModelBackendService implements GeneratedFeatureNamesProvider {

  constructor(private http: HttpClient, @Inject(BASE_URL) private baseUrl: string) { }

  getFeatureNames(project: GenericNamedProject): Observable<SampleFeature[]> {
    let realProject = project as ModelBuilderData;

    if (realProject.dynamicRegion) {
      return this.get(realProject.modelID).pipe(map(tpl => {
        let ourRegion: CdssBodyRegion;

        for (let region of tpl.data.bodyRegions) {
          if (region.id === realProject.dynamicRegion) {
            ourRegion = region;
            break;
          }
        }

        if (!ourRegion) {
          console.error(`Failed to find dynamic region ${realProject.dynamicRegion} in the CDSS application. Has the region been removed / ID changed?`);
          return [];
        }

        let rv: SampleFeature[] = [];

        for (let feature of tpl.data.extractedFeatures) {
          let prefix = feature.roleVariants.join('+');
          let featureName = prefix + "." + feature.feature;
    
          rv.push({
            name: `${ourRegion.featureNamePrefix}.${featureName}`
          });
        }

        return rv;
      }));
    } else {
      return this.get(realProject.modelID).pipe(map(tpl => {
        let dicomTagFeatures = [];
        
        tpl.data.roles.forEach(role => {
          role.extractedTags.forEach(tag => {
            dicomTagFeatures.push({ name: `${role.id}.dicom.${tag.tag}` });
          });
        });

        let labelColumns = tpl.data.labels?.map(label => {
          return {
            name: label.name,
            editable: true,
            isLabel: true,
          };
        });

        let manualFeatures = tpl.data.manualFeatures?.map(feature => {
          return {
            name: feature.name,
            editable: true,
            isLabel: false,
          };
        });

        let extracted = tpl.data.extractedFeatures.map(feature => {
          let prefix = feature.roleVariants.join('+');
          let featureName = prefix + "." + feature.feature;
    
          return { name: featureName };
        });

        if (tpl.data.bodyRegions) {
          let baseNames = extracted;
          extracted = [];

          tpl.data.bodyRegions.forEach(region => {
            if (!region.featureExtraction)
              return;

            let prefix = "";
            if (region.featureNamePrefix) {
              prefix = `${region.featureNamePrefix}.`;
            }

            extracted.push(...baseNames.map(ft => { return { name: prefix + ft.name }; }));
          });
        }

        return extracted.concat(dicomTagFeatures).concat(labelColumns ?? []).concat(manualFeatures ?? []);
        // .concat(project.customColumns ?? [])
      }));
    }
  }

  public listModels(includeNonPublic: boolean, filterByOrgan?: string, from?: number, count?: number): Observable<ResultRange<ModelListItem>> {
    let params = new HttpParams();
    if (from !== undefined)
      params = params.set("from", from.toFixed(0));

    if (count !== undefined)
      params = params.set("count", count.toFixed(0));
    
    if (filterByOrgan)
      params = params.set("organ", filterByOrgan);

    params = params.set("all", includeNonPublic ? "true" : "false");

    return this.http.get<ModelListItem[]>(this.baseUrl + '/cdss/models', { params, observe: "response" })
      .pipe(map(resp => {
        let range = resp.headers.get("content-range");

        if (range) {
          let result = {
            totalCount: parseInt(range.split('/')[1]),
            data: resp.body,
          };

          return result;
        } else {
          return {
            data: resp.body,
            totalCount: resp.body.length,
          };
        }
      })).pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 416) {
          let res: ResultRange<ModelListItem> = {
            totalCount: 0,
            data: [],
          };
          return of(res);
        }
        throw err;
      }));
  }

  public save(tpl: CdssModel, id?: string): Observable<string> {
    let url;

    if (id != undefined) {
      url = this.baseUrl + `/cdss/models/${id}`;

      return this.http.put<void>(url, tpl).pipe(map(() => id));
    } else {
      url = this.baseUrl + "/cdss/models";

      return this.http.post<void>(url, tpl, { observe: 'response' }).pipe(map(resp => {
        return resp.headers.get('location').split('/').pop();
      }));
    }
  }

  public useCount(id: string): Observable<number> {
    return this.http.get<object>(this.baseUrl + `/cdss/models/${id}/useCount`).pipe(map(resp => {
      return resp['count'];
    }));
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `/cdss/models/${id}`);
  }

  public get(id: string, sharingCode?: string): Observable<CdssModel> {
    return this.http.get<CdssModel>(this.baseUrl + `/cdss/models/${id}`, { params: sharingCodeParams(sharingCode) });
  }

  public getExistingOrganValues(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + '/cdss/models/organs');
  }

  public findSuitableAutomlModels(modelId: string): Observable<SuitableAutoMLModel[]> {
    return this.http.get<SuitableAutoMLModel[]>(this.baseUrl + `/automl-submission/by-internal-tag?name=cdss-model&value=${encodeURIComponent(modelId)}`).pipe(map(res => {
      res.forEach(submission => {
        submission.createdAt = new Date(submission.createdAt);
      });
      return res;
    }));
  }

  // For CDSS Q.C. report
  public getAutomlTMLPerformance(submissionId: string) {
    return this.http.get<AutoMLPublicPerformance>(this.baseUrl + `/automl-submission/${submissionId}/report/tml-performance`);
  }
}

export function niftiImagesForRendering(model: CdssModel, pipelines: DLPipeline[], roleAssignment?: Map<string,string>, noBodyRegionImages?: boolean): {[ id: string ]: string} {
  let rv = {};

  let pipeline = pipelines.find(dl => dl.id === model.dlPipeline);

  if (pipeline) {
    pipeline.providedBackgrounds?.forEach(bg => {
      rv[bg.id] = `BCKs/${bg.filename || bg.id}.nii`;
    });

    pipeline.providedGenericMasks?.forEach(mask => {
      rv[mask.id] = `VOIs/${mask.filename || mask.id}.nii`;
    });
  }
  
  model.data.roles?.forEach(role => {
    if (roleAssignment?.has(role.id)) {
      if (role.normalizeToSUV)
        rv[role.id] = `Series/${roleAssignment.get(role.id)}-SUV.nii.gz`;
      else
        rv[role.id] = `Series/${roleAssignment.get(role.id)}.nii.gz`;
    } else
      rv[role.id] = `Series/${role.filename || role.id}.nii`;
  });
  if (!noBodyRegionImages || !pipeline) {
    if (model.data.bodyRegions) {
      model.data.bodyRegions.forEach(region => {
        if (region.voi) {
          rv[`region_${region.id}`] = {
            regexp: `VOIs/${region.voi}\\.nii(\\.gz)?`,
            handling: region.voiHandling,
          };
        }
      });
    } else {
      // backwards compatibility
      rv['voi'] = "VOIs/voi.nii";
    }
  }

  return rv;
}

export interface ModelBuilderData extends GenericNamedProject {
  modelID: string;
  modelName: string;
  dynamicRegion?: string;
  description?: string;

  // inherited:
  // name: string;
  // sampleCount: number;
  // customColumns?: string[];
}
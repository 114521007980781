import { LocalizeFn } from "@angular/localize/init";
import { NamedBlob, NormalizeByValue, SerieUid } from "./dicom-common";
import { DicomRole } from "./dicom-role";
import { DicomAnalysisResultPatient, DicomAnalysisResultSerie, DicomAnalysisResultStudy } from "./dicom.service";
import { ProjectTemplateData, ProjectTemplateRole, RadiomicsRoleVariant } from "./radiomics-common";
import { RadiomicsConfig } from "./radiomics-config";
import { RoleHints } from "./dicom-node/config";

export const dicomModalities = [
	{
		id: "CT",
		name: $localize `Computed Tomography (CT)`
	},
	{
		id: "MR",
		name: $localize `Magnetic Resonance (MRI)`
	},
	{
		id: "PT",
		name: $localize `Positron emission tomography (PET)`
	},
];

export interface DicomTag {
	id: string;
	name: string;
	validate?: (value: string) => boolean;
}

function validateNonZeroFloat(value: string) {
	let v = parseFloat(value);

	if (isNaN(v) || !isFinite(v))
		return false;
	return v > 0;
}

function validatePositiveFloat(value: string) {
	let v = parseFloat(value);

	if (isNaN(v) || !isFinite(v))
		return false;
	return v >= 0;
}

function validatePatientAge(value: string) {
	if (value.endsWith("Y"))
		value = value.substring(0, value.length-1);
	
	return validatePositiveFloat(value);
}

// DICOM tags we support extracting as features
export const dicomTags: DicomTag[] = [
	{
		id: "PatientAge",
		name: $localize `Patient's age (years)`,
		validate: validatePatientAge,
	},
	{
		id: "PatientWeight",
		name: $localize `Patient's weight (kg)`,
		validate: validateNonZeroFloat,
	},
	{
		id: "PatientSize",
		name: $localize `Patient's height (m)`,
		validate: validateNonZeroFloat,
	},
	{
		id: "PatientSex",
		name: $localize `Patient's sex`,
		validate: value => ["M","F","O"].includes(value.toUpperCase()),
	},
	{
		id: "RadionuclideDose",
		name: $localize `Radionuclide total dose (Bq)`,
		validate: validateNonZeroFloat,
	},
];

// DICOM tags we support for role matching
export const matchableDicomTags: DicomTag[] = [
	{
		id: "Modality",
		name: $localize `Modality`,
	},
	{
		id: "Radionuclide",
		name: $localize `Radionuclide`
	},
	{
		id: "Radiopharmaceutical",
		name: $localize `Radiopharmaceutical`
	},
	{
		id: "PatientSex",
		name: $localize `Patient Sex`
	},
	{
		id: "Units",
		name: $localize `Units`,
	},
	{
		id: "MRIType",
		name: $localize `MRI Type`,
	},
	{
		id: "MediaStorageType",
		name: $localize `Media Storage Type`,
	},
];

export const dicomUnits = [
	{
		id: "CNTS",
		name: $localize `counts`
	},
	{
		id: "NONE",
		name: $localize `unitless`
	},
	{
		id: "CM2",
		name: $localize `centimeter**2`
	},
	{
		id: "CM2ML",
		name: $localize `centimeter**2/milliliter`
	},
	{
		id: "PCNT",
		name: $localize `percent`
	},
	{
		id: "CPS",
		name: $localize `counts/second`
	},
	{
		id: "BQML",
		name: $localize `Becquerels/milliliter`
	},
	{
		id: "MGMINML",
		name: $localize `milligram/minute/milliliter`
	},
	{
		id: "UMOLMINML",
		name: $localize `micromole/minute/milliliter`
	},
	{
		id: "MLMING",
		name: $localize `milliliter/minute/gram`
	},
	{
		id: "MLG",
		name: $localize `milliliter/gram`
	},
	{
		id: "1CM",
		name: $localize `1/centimeter`
	},
	{
		id: "UMOLML",
		name: $localize `micromole/milliliter`
	},
	{
		id: "PROPCNTS",
		name: $localize `proportional to counts`
	},
	{
		id: "PROPCPS",
		name: $localize `proportional to counts/sec`
	},
	{
		id: "MLMINML",
		name: $localize `milliliter/minute/milliliter`
	},
	{
		id: "MLML",
		name: $localize `milliliter/milliliter`
	},
	{
		id: "GML",
		name: $localize `grams/milliliter`
	},
	{
		id: "STDDEV",
		name: $localize `standard deviations`
	},
];

// The list doesn't include non-PET(?) isotopes
// https://dicom.nema.org/medical/dicom/current/output/chtml/part16/sect_CID_18.html
export const dicomRadionuclides = [
	{
		id: "^11^Carbon",
		name: $localize `Carbon-11`,
		code: "C-105A1"
	},
	{
		id: "^13^Nitrogen",
		name: $localize `Nitrogen-13`,
		code: "C-107A1"
	},
	{
		id: "^14^Oxygen",
		name: $localize `Oxygen-14`,
		code: "C-1018C"
	},
	{
		id: "^15^Oxygen",
		name: $localize `Oxygen-15`,
		code: "C-B1038"
	},
	{
		id: "^18^Fluorine",
		name: $localize `Fluorine-18`,
		code: "C-111A1"
	},
	{
		id: "^22^Sodium",
		name: $localize `Sodium-22`,
		code: "C-155A1"
	},
	{
		id: "^38^Potassium",
		name: $localize `Potassium-38`,
		code: "C-135A4"
	},
	{
		id: "^43^Scandium",
		name: $localize `Scandium-43`
	},
	{
		id: "^44^Scandium",
		name: $localize `Scandium-44`
	},
	{
		id: "^45^Titanium",
		name: $localize `Titanium-45`,
		code: "C-166A2"
	},
	{
		id: "^51^Manganese",
		name: $localize `Manganese-51`
	},
	{
		id: "^52^Iron",
		name: $localize `Iron-52`,
		code: "C-130A1"
	},
	{
		id: "^52^Manganese",
		name: $localize `Manganese-52`,
		code: "C-149A1"
	},
	{
		id: "^52m^Manganese",
		name: $localize `Manganese-52m`,
	},
	{
		id: "^60^Copper",
		name: $localize `Copper-60`,
		code: "C-127A4"
	},
	{
		id: "^61^Copper",
		name: $localize `Copper-61`,
		code: "C-127A1"
	},
	{
		id: "^62^Copper",
		name: $localize `Copper-62`,
		code: "C-127A5"
	},
	{
		id: "^62^Zinc",
		name: $localize `Zinc-62`,
		code: "C-141A1"
	},
	{
		id: "^64^Copper",
		name: $localize `Copper-64`,
		code: "C-127A2"
	},
	{
		id: "^66^Gallium",
		name: $localize `Gallium-66`,
		code: "C-131A1"
	},
	{
		id: "^68^Gallium",
		name: $localize `Gallium-68`,
		code: "C-131A3"
	},
	{
		id: "^68^Germanium",
		name: $localize `Germanium-68`,
		code: "C-128A2"
	},
	{
		id: "^70^Arsenic",
		name: $localize `Arsenic-70`
	},
	{
		id: "^72^Arsenic",
		name: $localize `Arsenic-72`,
		code: "C-115A2"
	},
	{
		id: "^73^Selenium",
		name: $localize `Selenium-73`,
		code: "C-116A2"
	},
	{
		id: "^75^Bromine",
		name: $localize `Bromine-75`,
		code: "C-113A1"
	},
	{
		id: "^76^Bromine",
		name: $localize `Bromine-76`,
		code: "C-113A2"
	},
	{
		id: "^77^Bromine",
		name: $localize `Bromine-77`,
		code: "C-113A3"
	},
	{
		id: "^82^Rubidium",
		name: $localize `Rubidium-82`,
		code: "C-159A2"
	},
	{
		id: "^86^Yttrium",
		name: $localize `Yttrium-86`,
		code: "C-162A3"
	},
	{
		id: "^89^Zirconium",
		name: $localize `Zirconium-89`,
		code: "C-168A4"
	},
	{
		id: "^90^Niobium",
		name: $localize `Niobium-90`
	},
	{
		id: "^90^Yttrium",
		name: $localize `Yttrium-90`,
		code: "C-162A7"
	},
	{
		id: "^94m^Technetium",
		name: $localize `Technetium-94m`,
		code: "C-163AA"
	},
	{
		id: "^124^Iodine",
		name: $localize `Iodine-124`,
		code: "C-114A5"
	},
	{
		id: "^152^Terbium",
		name: $localize `Terbium-152`
	}
];

export const dicomRadiopharmaceuticals = [
	{
		id: "Acetate C^11^",
		name: $localize `Acetate C^11^`,
		code: "C-B1043"
	},
	{
		id: "Ammonia N^13^",
		name: $localize `Ammonia N^13^`,
		code: "C-B103C"
	},
	{
		id: "ATSM Cu^64^",
		name: $localize `ATSM Cu^64^`,
		code: "C-B07DB"
	},
	{
		id: "Butanol O^15^",
		name: $localize `Butanol O^15^`,
		code: "C-B07DC"
	},
	{
		id: "Carbon dioxide O^15^",
		name: $localize `Carbon dioxide O^15^`,
		code: "C-B103B"
	},
	{
		id: "Carbon monoxide C^11^",
		name: $localize `Carbon monoxide C^11^`,
		code: "C-B1045"
	},
	{
		id: "Carbon monoxide O^15^",
		name: $localize `Carbon monoxide O^15^`,
		code: "C-B103A"
	},
	{
		id: "Carfentanil C^11^",
		name: $localize `Carfentanil C^11^`,
		code: "C-B103F"
	},
	{
		id: "EDTA Ga^68^",
		name: $localize `EDTA Ga^68^`,
		code: "C-B07DD"
	},
	{
		id: "Florbetaben F^18^",
		name: $localize `Florbetaben F^18^`,
		code: "C-D6858"
	},
	{
		id: "Florbetapir F^18^",
		name: $localize `Florbetapir F^18^`,
		code: "C-E0269"
	},
	{
		id: "Fluciclatide F^18^",
		name: $localize `Fluciclatide F^18^`,
		code: "C-E0265"
	},
	{
		id: "Fluciclovine F^18^",
		name: $localize `Fluciclovine F^18^`,
		code: "C-E026A"
	},
	{
		id: "Flumazenil C^11^",
		name: $localize `Flumazenil C^11^`,
		code: "C-B07DE",
		alternateIds: [] // disable automatic alternate ID because it would be imprecise (C11 vs F18)
	},
	{
		id: "Flumazenil F^18^",
		name: $localize `Flumazenil F^18^`,
		code: "C-B07DF",
		alternateIds: []
	},
	{
		id: "Fluorethyltyrosin F^18^",
		name: $localize `Fluorethyltyrosin F^18^`,
		code: "C-B07E0"
	},
	{
		id: "Fluorobenzothiazole F^18^",
		name: $localize `Fluorobenzothiazole F^18^`,
		code: "C-B07E4"
	},
	{
		id: "Fluorocholine F^18^",
		name: $localize `Fluorocholine F^18^`,
		code: "C-E0273"
	},
	{
		id: "Fluorodeoxyglucose F^18^",
		name: $localize `Fluorodeoxyglucose F^18^`,
		code: "C-B1031"
	},
	{
		id: "Fluoro-L-dopa F^18^",
		name: $localize `Fluoro-L-dopa F^18^`,
		code: "C-E0241"
	},
	{
		id: "Fluoromethane F^18^",
		name: $localize `Fluoromethane F^18^`,
		code: "C-B07E2"
	},
	{
		id: "Fluoromisonidazole F^18^",
		name: $localize `Fluoromisonidazole F^18^`,
		code: "C-B07E1"
	},
	{
		id: "Fluorouracil F^18^",
		name: $localize `Fluorouracil F^18^`,
		code: "C-B07E3"
	},
	{
		id: "Flutemetamol F^18^",
		name: $localize `Flutemetamol F^18^`,
		code: "C-E0267"
	},
	{
		id: "Germanium Ge^68^",
		name: $localize `Germanium Ge^68^`,
		code: "C-128A2"
	},
	{
		id: "Glutamate N^13^",
		name: $localize `Glutamate N^13^`,
		code: "C-B103D"
	},
	{
		id: "Mespiperone C^11^",
		name: $localize `Mespiperone C^11^`,
		code: "C-B07E5"
	},
	{
		id: "Methionine C^11^",
		name: $localize `Methionine C^11^`,
		code: "C-B103E"
	},
	{
		id: "Monoclonal antibody I^124^",
		name: $localize `Monoclonal antibody I^124^`,
		code: "C-114AA"
	},
	{
		id: "Oxygen O^15^",
		name: $localize `Oxygen O^15^`,
		code: "C-B1038"
	},
	{
		id: "Oxygen-water O^15^",
		name: $localize `Oxygen-water O^15^`,
		code: "C-B1039"
	},
	{
		id: "Palmitate C^11^",
		name: $localize `Palmitate C^11^`,
		code: "C-B1044"
	},
	{
		id: "PSMA-11 Ga^68^",
		name: $localize `PSMA-11 Ga^68^`,
		alternateIds: ["PSMA-11", "PSMA"]
	},
	{
		id: "PTSM Cu^62^",
		name: $localize `PTSM Cu^62^`,
		code: "C-B07E7"
	},
	{
		id: "Raclopride C^11^",
		name: $localize `Raclopride C^11^`,
		code: "C-B1042"
	},
	{
		id: "Rubidium chloride Rb^82^",
		name: $localize `Rubidium chloride Rb^82^`,
		code: "C-B1037"
	},
	{
		id: "Sodium fluoride F^18^",
		name: $localize `Sodium fluoride F^18^`,
		code: "C-B1032"
	},
	{
		id: "Sodium iodide I^124^",
		name: $localize `Sodium iodide I^124^`,
		code: "C-B07E8"
	},
	{
		id: "Sodium Na^22^",
		name: $localize `Sodium Na^22^`,
		code: "C-155A1"
	},
	{
		id: "Spiperone F^18^",
		name: $localize `Spiperone F^18^`,
		code: "C-B1033"
	},
	{
		id: "Thymidine (FLT) F^18^",
		name: $localize `Thymidine (FLT) F^18^`,
		code: "C-B1036"
	}
];

export const dicomMriTypes = [
	{
		id: "T1w",
		name: $localize `T1-Weighted`
	},
	{
		id: "T2w",
		name: $localize `T2-Weighted`
	},
	{
		id: "Flair",
		name: $localize `Fluid Attenuated Inversion Recovery (Flair)`
	},
	{
		id: "PDw",
		name: $localize `PD (proton-density) weighting`
	},
	{
		id: "DWI",
		name: $localize `Diffusion-weighted (DWI)`
	},
	{
		id: "ADC",
		name: $localize `Apparent diffusion coefficient (ADC)`
	},
];

// Find all here: https://dicom.nema.org/dicom/2013/output/chtml/part04/sect_i.4.html
export const dicomMediaStorageTypes = [
	{
		id: "CTImageStorage",
		name: "CT Image Storage",
		code: ["1.2.840.10008.5.1.4.1.1.2", "1.2.840.10008.5.1.4.1.1.2.1", "1.2.840.10008.5.1.4.1.1.2.2"]
	},
	{
		id: "PositronEmissionTomographyImageStorage",
		name: "PET Image Storage",
		code: ["1.2.840.10008.5.1.4.1.1.128", "1.2.840.10008.5.1.4.1.1.130", "1.2.840.10008.5.1.4.1.1.128.1"]
	},
	{
		id: "MRImageStorage",
		name: "MR Image Storage",
		code: ["1.2.840.10008.5.1.4.1.1.4", "1.2.840.10008.5.1.4.1.1.4.1"]
	},
];

export interface SuitabilityResult {
	problems: SuitabilityProblem[];
	extractedFeatures: object;
}

export interface SuitabilityProblem {
	tag: string;
	expectedValue?: string;
	actualValue?: any | null;
	overridable: boolean;
	hintKind?: "negative" | "positive";
}

function detectMRIType(serie: DicomAnalysisResultSerie) {
	if (serie.echoTime === undefined || serie.repetitionTime === undefined)
		return;
	if (serie.imageType?.includes("\\ADC"))
		return "ADC";

	// https://www.na-mic.org/wiki/NAMIC_Wiki:DTI:DICOM_for_DWI_and_DTI
	if (serie.hasMriDiffusionSeq)
		return "DWI";

	// https://case.edu/med/neurology/NR/MRI%20Basics.htm
	// https://mriquestions.com/image-contrast-trte.html
	// TODO: https://www.hindawi.com/journals/tswj/2014/735762/tab3/
	// TODO: https://radiopaedia.org/articles/mri-sequence-parameters
	if (serie.repetitionTime > 8500)
		return "Flair";
	else if (serie.repetitionTime < 2000)
		return "T1w";
	else {
		if (serie.echoTime < 60)
			return "PDw";
		else
			return "T2w";
	}
}

function detectRadionuclide(serie: DicomAnalysisResultSerie) {
	if (serie.radionuclideCode) {
		for (let i = 0; i < dicomRadionuclides.length; i++) {
			if (dicomRadionuclides[i].code === serie.radionuclideCode)
				return dicomRadionuclides[i].id;
		}
	}

	return serie.radionuclide;
}

function detectRadiopharmaceutical(serie: DicomAnalysisResultSerie) {
	if (serie.radiopharmaceuticalCode) {
		for (let i = 0; i < dicomRadiopharmaceuticals.length; i++) {
			if (dicomRadiopharmaceuticals[i].code === serie.radiopharmaceuticalCode)
				return dicomRadiopharmaceuticals[i].id;
		}
	}

	// Try name matching
	if (serie.radiopharmaceutical) {
		const lowercase = serie.radiopharmaceutical.toLowerCase();

		for (let i = 0; i < dicomRadiopharmaceuticals.length; i++) {
			const ph = dicomRadiopharmaceuticals[i];

			if (ph.id === serie.radiopharmaceutical)
				return ph.id;

			let alternate: string[] = ph.alternateIds;
			if (!alternate) {
				let parts = ph.id.split(' ');
				if (parts.length === 2)
					alternate = [ parts[0] ];
			}

			if (alternate && alternate.length > 0) {
				for (let j = 0; j < alternate.length; j++) {
					if (lowercase.includes(alternate[j].toLowerCase()))
						return ph.id;
				}
			}
		}

		return serie.radiopharmaceutical;
	}
}

function extractTagValue(tag: string, serie: DicomAnalysisResultSerie, study: DicomAnalysisResultStudy, patient: DicomAnalysisResultPatient): any {
	let value;
	let validator = dicomTags.find(t => t.id == tag)?.validate;

	switch (tag) {
		case "PatientAge": {
			if (typeof study.patientAge === 'string' && study.patientAge?.endsWith("Y"))
				return parseInt(study.patientAge);
			
			value = study.patientAge;
			break;
		}
		case "PatientWeight":
			value = study.patientWeight;
			break;
		case "PatientSize":
			value = study.patientSize;
			break;
		case "PatientSex":
			value = patient.sex;
			break;
		case "Modality":
			value = serie.modality;
			break;
		case "Radionuclide":
			value = detectRadionuclide(serie);
			break;
		case "RadionuclideDose":
			value = serie.radionuclideDose;
			break;
		case "Units":
			value = serie.units;
			break;
		case "MRIType":
			value = detectMRIType(serie);
			break;
		case "Radiopharmaceutical":
			value = detectRadiopharmaceutical(serie);
			break;
		case "SeriesTime":
			value = dicomTimeToSeconds(serie.seriesTime);
			break;
		case "RadionuclideHalfLife":
			value = serie.radionuclideHalfLife;
			break;
		case "RadiopharmaceuticalStartTime":
			value = dicomTimeToSeconds(serie.radiopharmaceuticalStartTime);
			break;
		case "MediaStorageType":
			value = dicomMediaStorageTypes.find(type => type.code.some(code => serie.mediaStorageType === code))?.id ?? serie.mediaStorageType;
			break;
		default:
			return null;
	}

	if (value !== undefined && validator && !validator(value)) {
		// Tag value validation failed
		return null;
	}

	return value;
}

const suvNormTags = [ "PatientWeight", "RadionuclideDose", "SeriesTime", "RadionuclideHalfLife", "RadiopharmaceuticalStartTime" ];

export function serieSuitableForCdssRole(serie: DicomAnalysisResultSerie, study: DicomAnalysisResultStudy, patient: DicomAnalysisResultPatient, role: DicomRole, roleHints?: RoleHints): SuitabilityResult {

	let result: SuitabilityResult = {
		problems: [],
		extractedFeatures: {},
	};

	// Rol hints - used in DICOM Nodes only
	if (roleHints) {
		if (roleHints.negativeRules) {
			for (const rule of roleHints.negativeRules) {
				const re = new RegExp(rule.seriesDescription);
				if (re.exec(serie.description)) {
					result.problems.push({
						tag: "SeriesDescription",
						actualValue: serie.description,
						expectedValue: rule.seriesDescription,
						hintKind: "negative",
						overridable: false,
					});
				}
			}
		}

		if (result.problems.length === 0 && roleHints.positiveRules?.length) {
			let matched = false;
			for (const rule of roleHints.positiveRules) {
				const re = new RegExp(rule.seriesDescription);
				if (re.exec(serie.description)) {
					matched = true;
					break;
				}
			}

			// Not matched by any positive value
			if (!matched) {
				result.problems.push({
					tag: "SeriesDescription",
					actualValue: serie.description,
					hintKind: "positive",
					overridable: false,
				});
			}
		}
	}

	if (!serie.imageAttributes) {
		result.problems.push({
			tag: "PixelData",
			overridable: false,
		});
	}
	
	if (role.dicomMatches) {
		for (let i = 0; i < role.dicomMatches.length; i++) {
			const match = role.dicomMatches[i];

			let value = extractTagValue(match.tag, serie, study, patient);
			if (value != match.value) {
				result.problems.push({
					tag: match.tag,
					expectedValue: match.value,
					actualValue: value,
					overridable: !!match.overridable,
				});
			}
		}
	}

	// For SUV normalization, we need these 5 DICOM tags to be present
	// even if not explicitly specified.

	// Since the introduction of serie previews in the browser, we always extract these 5 tags
	// to provide nicer previews of PET scans.
	if (/*role.normalizeToSUV*/ true) {
		role = Object.assign({}, role);

		if (!role.extractedTags)
			role.extractedTags = [];

		for (let tag of suvNormTags) {
			if (!role.extractedTags.some(etag => etag.tag === tag)) {
				role.extractedTags.push({ tag, optional: !role.normalizeToSUV });
			}
		}
	}

	if (role.extractedTags) {
		for (let i = 0; i < role.extractedTags.length; i++) {
			const ex = role.extractedTags[i];
			let value = extractTagValue(ex.tag, serie, study, patient);

			if (value === undefined || value === null) {
				if (!ex.optional) {
					result.problems.push({
						tag: ex.tag,
						overridable: false,
					});
				}
			} else {
				let featureName = `${role.id}.dicom.${ex.tag}`;
				result.extractedFeatures[featureName] = value;
			}
		}
	}

	if (serie.imageAttributes) {
		const spacing = serie.imageAttributes.spacing;
		if (role.minResX !== undefined && spacing[0] > role.minResX) {
			result.problems.push({
				overridable: true,
				tag: $localize `X-axis spacing (resolution)`,
				expectedValue: `≤ ${role.minResX}mm`,
				actualValue: `${spacing[0]}`,
			});
		}

		if (role.minResY !== undefined && spacing[1] > role.minResY) {
			result.problems.push({
				overridable: true,
				tag: $localize `Y-axis spacing (resolution)`,
				expectedValue: `≤ ${role.minResY}mm`,
				actualValue: `${spacing[1]}`,
			});
		}

		if (role.minResZ !== undefined && spacing[2] > role.minResZ) {
			result.problems.push({
				overridable: true,
				tag: $localize `Z-axis spacing (resolution)`,
				expectedValue: `≤ ${role.minResZ}mm`,
				actualValue: `${spacing[2]}`,
			});
		}
	}

	return result;
}

export interface DicomSerie {
	// Filename to be used on the filesystem (for the Nifti file), e.g. role1.nii
	targetFileName: string;
	// Name to be shown to the user, e.g. AC_CT
	friendlyName: string;
	// Dicom files forming this serie
	files: NamedBlob[] | null;
	filenames: string[];
}

export function configureRadiomicsForDicomSubmission(config: RadiomicsConfig, roleAssignment: Map<string,string>, loadedFiles: Map<string, NamedBlob>,
    serieUidMap: Map<SerieUid, DicomAnalysisResultSerie>, model: ProjectTemplateData, dicomValues: object) {

	let series = new Map<string, DicomSerie & NormalizeByValue>();

	model.roles.forEach(role => {
		let serieUid = roleAssignment.get(role.id);

		const normalizeLinear = dicomSUVNormalization(role, dicomValues);
		let filename: string;

		if (normalizeLinear === undefined)
			filename = `${serieUid}.nii.gz`;
		else
			filename = `${serieUid}-SUV.nii.gz`;

		if (!series.has(filename)) {
			const serieData = serieUidMap.get(serieUid);
			let files = serieData.filenames.map(filename => loadedFiles.get(filename));

			series.set(filename, {
				files,
				filenames: serieData.filenames,
				targetFileName: filename,
				friendlyName: serieData.description || serieData.modality,
				normalizeByValue: normalizeLinear,
			});
		}

		config.series.push({
			name: filename,
			roleName: role.id,
		});
	});

	config.delineation = {
		method: "VOIs",
		multiLesion: model.mergeVOIs ? "Merge" : null,
	};
	config.dichotomization = {
		method: model.maskHandling.method,
		binaryCutoff: model.maskHandling.binaryCutoff,
	};
	config.backgrounds = model.backgrounds.map(bg => {
		let roles = model.roles.flatMap(role => {
			if (role.useBackground === bg.id)
				return [role.id];
			return [];
		});

		let filename = bg.id;
		if (bg.filename)
			filename = `${bg.filename}.nii`;

		return {
			roleName: bg.id,
			name: filename,
			normalizesSeries: roles,
		};
	});
	config.saveSettingsIni();

	config.saveFeatureParameters(model.extractedFeatures);

	let variants: RadiomicsRoleVariant[] = [];
	model.roles.forEach(role => {
		role.variants.forEach(roleVariant => {
			let v: RadiomicsRoleVariant = {
				key: roleVariant.id,
				role: role.id,
				resolutionX: roleVariant.resolution[0],
				resolutionY: roleVariant.resolution[1],
				resolutionZ: roleVariant.resolution[2],
				binWidth: roleVariant.binWidth,
			};

			variants.push(v);
		});
	});
	config.saveRoleVariants(variants, model.extractedFeatures);

	return Array.from(series.values());
}

export interface DicomPatientNameRepresentation {
	lastName: string;
	firstName?: string;
	middleName?: string;
	namePrefix?: string;
	nameSuffix?: string;
}

export interface DicomPatientName {
	alphabetic: DicomPatientNameRepresentation;
	ideographic?: DicomPatientNameRepresentation;
	phonetic?: DicomPatientNameRepresentation;
}

export function parsePatientNameString(patientName: string): DicomPatientName {
	let result: DicomPatientName;
	let representations = patientName.split('=');

	for (let i = 0; i < representations.length; i++) {
		let parts = representations[i].split('^');

		let rep: DicomPatientNameRepresentation = {
			lastName: parts[0],
			firstName: parts[1],
			middleName: parts[2],
			namePrefix: parts[3],
			nameSuffix: parts[4],
		};

		switch (i) {
			case 0:
				result = { alphabetic: rep };
				break;
			case 1:
				result.ideographic = rep;
				break;
			case 2:
				result.phonetic = rep;
				break;
		}
	}

	return result;
}

function dicomTimeToSeconds(dicomTime: string): number {
	if (!dicomTime)
		return null;
		
	const re = /(\d\d)(\d\d)(\d\d(\.\d+)?)/;
	let grps = re.exec(dicomTime);
	return parseInt(grps[1]) * 60 * 60 + parseInt(grps[2]) * 60 + parseFloat(grps[3]);
}

export function dicomSUVNormalization(role: ProjectTemplateRole | string, extractedFeatures: object, force?: boolean): number {
	let roleId: string;

	if (typeof role !== 'string') {
		if (!force && !role.normalizeToSUV)
			return;
		roleId = role.id;
	} else {
		roleId = role;
	}

	let vals: any = {};
	for (let tag of suvNormTags) {
		let val = extractedFeatures[`${roleId}.dicom.${tag}`];

		if (val === undefined) {
			console.warn(`Missing ${tag} in extracted features of role ${roleId}`);
			return;
		}

		vals[tag] = val;
	}

	let seriesTime = vals.SeriesTime;
	let injectionTime = vals.RadiopharmaceuticalStartTime;
	let decayTime = seriesTime - injectionTime;

	let decayCorrectedDose = vals.RadionuclideDose * Math.pow(2, -(decayTime / vals.RadionuclideHalfLife));

	let factor = decayCorrectedDose / vals.PatientWeight / 1000;
	if (isNaN(factor) || !isFinite(factor) || factor <= 0)
		throw new Error($localize `Produced SUV normalization factor is invalid. Check input data.`);

	return factor;
}

import { KernelDensity } from "./kernel-density";
import { AutoMLPerformanceValues } from "./submission/model";

export interface ValueDistribution {
	min: number;
	max: number;
	mean: number;
	median: number;
	meanDev: number;
	stdDeviation: number;
	lowerQuartile: number;
	upperQuartile: number;

	// 95% confidence interval
	ci95From: number;
	ci95To: number;
}

export interface KernelDensityResult extends ValueDistribution {
	density: number[];	
}


export class PerformanceDataItem implements KernelDensityResult {
    min: number;
    max: number;
  
    median: number = 0;
    mean: number = 0;
    meanDev: number = 0;
    lowerQuartile: number = 0;
    upperQuartile: number = 0;
    all: number[] = [];
    density: number[] = [];
    stdDeviation: number = 0;
  
    // 95% confidence interval
    ci95From = 0;
    ci95To = 0;
  
    violinDensity: number[] = null;
    violinMin: number = null;
    violinMax: number = null;
  
    public extendViolinPlot() {
      if (!this.density)
        return;
  
      // Replace the sharp cutoff at min/max with a pointy tail
      const tailLength = 0.05;
      this.violinMax = Math.min(1.0, this.max + tailLength);
      this.violinMin = Math.max(0.0, this.min - tailLength);
  
      //const tailPoints = this.density.length / (this.max - this.min) * tailLength;
      let maxSideTailPoints = Math.round(this.density.length / (this.max - this.min) * (this.violinMax - this.max));
      let minSideTailPoints = Math.round(this.density.length / (this.max - this.min) * (this.min - this.violinMin));
  
      if (!isFinite(maxSideTailPoints))
        maxSideTailPoints = 0;
      else if (maxSideTailPoints > this.density.length / 2)
        maxSideTailPoints = Math.round(this.density.length / 2);
  
      if (!isFinite(minSideTailPoints))
        minSideTailPoints = 0;
      else if (minSideTailPoints > this.density.length / 2)
        minSideTailPoints = Math.round(this.density.length / 2);
  
      this.violinDensity = Array(minSideTailPoints).concat(this.density, Array(maxSideTailPoints));
  
      if (minSideTailPoints > 0) {
        let value = this.density[0];
        let divBy = Math.pow(1.5, 1/(minSideTailPoints/10));
  
        for (let i = minSideTailPoints-1; i > 0; i--) {
          value /= divBy;
          this.violinDensity[i] = value;
        }
        this.violinDensity[0] = 0;
      }
  
      if (maxSideTailPoints > 0) {
        let value = this.density[this.density.length - 1];
        let divBy = Math.pow(1.5, 1/(maxSideTailPoints/10));
        for (let i = minSideTailPoints + this.density.length; i < this.violinDensity.length - 1; i++) {
          value /= divBy;
          this.violinDensity[i] = value;
        }
        this.violinDensity[this.violinDensity.length - 1] = 0;
      }
    }
  
    private static graphY(yMax: number, yMin: number, valueRange: ValueRange, value: number): number {
      let graphHeight = yMin - yMax;
      let adaptedValue = (value - valueRange.min) / (valueRange.max - valueRange.min);
      return yMin - graphHeight * adaptedValue;
    }
  
    public toInterquartileRange(x: number, yMax: number, yMin: number, valueRange: ValueRange): string {
  
      let yQTop = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.upperQuartile);
      let yQBottom = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.lowerQuartile);
      let qHeight = yQBottom - yQTop;
  
      return `M ${x},${yQTop}
        l 0,${qHeight}\n`;
    }
  
    public toInterquartile15Range(x: number, yMax: number, yMin: number, valueRange: ValueRange): string {
  
      let yQTop = PerformanceDataItem.graphY(yMax, yMin, valueRange, (this.upperQuartile + this.max) / 2);
      let yQBottom = PerformanceDataItem.graphY(yMax, yMin, valueRange, (this.lowerQuartile + this.min) / 2);
      let qHeight = yQBottom - yQTop;
  
      return `M ${x},${yQTop}
        l 0,${qHeight}\n`;
    }
  
    public toViolinPlot(x: number, yMax: number, yMin: number, valueRange: ValueRange): string {
      const barWidth = 10;
  
      let yVMax = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.violinMax);
      let yVMin = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.violinMin);
      let plot = `M ${x},${yVMin}\n`;
  
      let height = yVMax - yVMin;
  
      for (let i = 0; i < this.violinDensity.length; i++) {
        let pointX = x - (barWidth/2) * this.violinDensity[i];
        let pointY = yVMin + i * (height / this.violinDensity.length);
        plot += `L ${pointX},${pointY}\n`;
      }
  
      plot += `L ${x},${yVMax}\n`;
  
      for (let i = this.violinDensity.length-1; i >= 0; i--) {
        let pointX = x + (barWidth/2) * this.violinDensity[i];
        let pointY = yVMin + i * (height / this.violinDensity.length);
        plot += `L ${pointX},${pointY}\n`;
      }
  
      plot += `L ${x},${yVMin}\n`;
  
      return plot;
    }
  
    public toMedianPoint(x: number, yMax: number, yMin: number, valueRange: ValueRange): string {
      const r = 3;
  
      let yMedian = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.median);
  
      return `M ${x} ${yMedian}
      l 0,-0.1`;
    }
  
    public toRangePath(x: number, yMax: number, yMin: number, valueRange: ValueRange): string {
  
      let yVMax = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.max);
      let yVMin = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.min);
      let yQTop = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.upperQuartile);
      let yQBottom = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.lowerQuartile);
  
      return `M ${x},${yVMax}
        l 0,${yQTop-yVMax}
        m 0,${yQBottom-yQTop}
        l 0,${yVMin-yQBottom}`;
    }
  
    public toRangeTicks(x: number, yMax: number, yMin: number, valueRange: ValueRange): string {
      const tickWidth = 3;
  
      let yVMax = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.max);
      let yVMin = PerformanceDataItem.graphY(yMax, yMin, valueRange, this.min);
  
      return `M ${x-tickWidth/2},${yVMax}
        l ${tickWidth},0
        m 0,${yVMin-yVMax}
        l ${-tickWidth},0`;
    }
}

  export class PerformanceData {
    SNS = new PerformanceDataItem();
    SPC = new PerformanceDataItem();
    PPV = new PerformanceDataItem();
    NPV = new PerformanceDataItem();
    ACC = new PerformanceDataItem();
    AUC?: PerformanceDataItem;

    public static fromPerformanceValues(mlData: AutoMLPerformanceValues[]): PerformanceData {
      const performanceData = new PerformanceData();

      for (let item of mlData) {
        let perf: AutoMLPerformanceValues = item;
  
        Object.keys(perf).forEach(perfKey => {
          const value = perf[perfKey];
  
          if (!performanceData[perfKey])
            performanceData[perfKey] = new PerformanceDataItem();
  
          performanceData[perfKey].all.push(value);
        });
      }
  
      Object.keys(performanceData).forEach(perfKey => {
        let item: PerformanceDataItem = performanceData[perfKey];
  
        if (item.all.length > 0) {
          let results = KernelDensity.estimate(item.all, 200);
  
          Object.assign(item, results);
          item.extendViolinPlot();
        }
      });

      return performanceData;
    }
}

export interface ValueRange {
    min: number;
    max: number;
}

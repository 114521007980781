import { AutoMLReactiveMetadata } from "../submission/submission-backend.service";
import { ColorRange } from "../themed-color";

const highConfidenceColor = [62, 129, 157];
const lowConfidenceColor = [226, 236, 240];
const confidenceColorRange = new ColorRange(lowConfidenceColor, highConfidenceColor, true);

// pictureId is used to generate unique color identifiers
export function generateEvaluationConfidenceMapTikz(pictureId: string, data: AutoMLReactiveMetadata) {
	let tikz = "";
	let maxDistance = 0;
	let weightSum = 0;
	let confidenceAccum = 0;

	const sampleIds = Object.keys(data);

	sampleIds.forEach((sampleId, index) => {
		const distance = data[sampleId][0];
		const weight = data[sampleId][1];
		const correctRatio = data[sampleId][2];
		let color = confidenceColorRange.colorArrayForValue(correctRatio);

		tikz += `\\definecolor{${pictureId}Conf${index}}{RGB}{${color[0]}, ${color[1]}, ${color[2]}}\n`;

		if (distance > maxDistance)
			maxDistance = distance;

		weightSum += weight;
		confidenceAccum += correctRatio * weight;
	});

	let confidence = confidenceAccum / weightSum;
	let color = confidenceColorRange.colorArrayForValue(confidence);

	tikz += `\\definecolor{ConfAbsMax}{RGB}{${highConfidenceColor[0]}, ${highConfidenceColor[1]}, ${highConfidenceColor[2]}}\n`;
	tikz += `\\definecolor{ConfAbsMin}{RGB}{${lowConfidenceColor[0]}, ${lowConfidenceColor[1]}, ${lowConfidenceColor[2]}}\n`;
	tikz += `\\definecolor{${pictureId}ConfAvg}{RGB}{${color[0]}, ${color[1]}, ${color[2]}}\n\n`;

	tikz += `\\begin{tikzpicture}\n`;

	// Center point
	tikz += `\\node [circle, very thick, draw=black, fill=${pictureId}ConfAvg, minimum size=8mm, text=white] (center) {${(100*confidence).toFixed(0)}\\%};\n\n`;

	let distanceRatio = 3 / maxDistance;

	sampleIds.forEach((sampleId, index) => {
		const distance = data[sampleId][0];
		const correctRatio = data[sampleId][2];

		let angle = index * 360 / sampleIds.length;
		let textAngle = (angle < 180) ? 90 : 270;
		tikz += `\\node[draw=ConfAbsMax, thin, fill=${pictureId}Conf${index}, minimum size=4mm, circle, label={${textAngle}:${(100*correctRatio).toFixed(0)}\\%}] at ({${angle}}:${distance*distanceRatio+1}) (sample${index}) {};\n`;
		tikz += `\\draw (center)--(sample${index});\n\n`;
	});

	// tikz += `\\node [rectangle, thin, draw=black, right color=${pictureId}ConfAbsMax, left color=${pictureId}ConfAbsMin, minimum width=30mm, minimum height=4mm, label={right:100\\%}, label={left:0\\%}, below = 4.5 of center] (box){};\n`;
	tikz += `\\end{tikzpicture}\n`;
	return tikz;
}

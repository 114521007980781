import { Injectable, OnDestroy } from "@angular/core";
import { CdssExtractedFeature, CdssPatientData, CdssPrediction, CdssPredictionResult, CdssReportService, ReportContext } from './cdss-report.service';
import { AutoMLReactiveMetadata, CdssReportData, CdssReportPredictionData, CdssSubmissionItem, SubmissionBackendService } from "../submission/submission-backend.service";
import { ModelBackendService } from "../model-backend.service";
import { DicomDatePipe } from "../dicom-date.pipe";
import { AutoMLPublicPerformance, CdssModel, CdssRole } from "../submission/model";
import { decryptString, decryptStringWithSymmetricKey, importUsersPrivateKey } from "../crypto-common";
import { dicomTags, matchableDicomTags } from "../dicom-definitions";
import { ProjectTemplateRoleVariant } from "../radiomics-common";
import { PerformanceData } from "../performance";
import { calculateDataIntegrityScore, DataIntegrityMetadataWithName, DataIntegrityStatus, generateDataIntegrityMapTikz, orderDataIntegrityEntries } from "../data-integrity-map";
import { ColorRange } from "../themed-color";
import { definedRadiomicsFeatures } from "../radiomics-features";
import { generateEvaluationConfidenceMapTikz } from "./evaluation-confidence-map";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'any'
})
export class CdssReportGeneratorService  {
  constructor(private cdssReport: CdssReportService, private backend: SubmissionBackendService,
    private modelBackend: ModelBackendService, private dicomDatePipe: DicomDatePipe) {
  }

  public run(params: ReportGenerationParams): Promise<Blob> {
    let submissionData: CdssSubmissionItem;
    let reportData: CdssReportData;
    let patientData: CdssPatientData;
    let renderList: string[];
    let renderedFiles = {};
    let modelData: CdssModel;
    const performanceData = new Map<string, AutoMLPublicPerformance>(); // prediction name -> perf

    let symmetricKey: Promise<string>;

    if (params.sharingCode)
      symmetricKey = firstValueFrom(this.backend.getSymmetricKeyFromSharingCode(params.submissionId, params.sharingCode));

    return firstValueFrom(this.backend.getSubmission(params.submissionId, params.sharingCode))
    .then(sd => {
      submissionData = sd;

      return firstValueFrom(this.backend.getReport(params.submissionId, params.sharingCode));
    })
    .then((rd): Promise<CryptoKey | string > => {
      reportData = rd;

      return params.privateKey ? importUsersPrivateKey(params.crypto, params.privateKey) : symmetricKey;
    })
    .then(privateOrSymmetricKey => {
      if (params.privateKey)
        return decryptString(globalThis.crypto, submissionData.patientSensitiveData, privateOrSymmetricKey as CryptoKey);
      else
        return decryptStringWithSymmetricKey(globalThis.crypto, submissionData.patientSensitiveData, privateOrSymmetricKey as string);
    })
    .then(decryptedDataString => {
      patientData = JSON.parse(decryptedDataString);

      if (patientData.age)
        patientData.age = parseInt(patientData.age).toFixed(0);
      if (typeof patientData.patientBirthDate === 'string')
        patientData.patientBirthDate = this.dicomDatePipe.transform(patientData.patientBirthDate);
      if (typeof patientData.studyDate === 'string')
        patientData.studyDate = this.dicomDatePipe.transform(patientData.studyDate);

      return this.modelBackend.get(submissionData.modelId, params.sharingCode).toPromise();
    })
    .then(md => {
      modelData = md;

      if (modelData.data.labels?.length) {
        let modelIndex = 0;

        const doNext = () => {
          if (modelIndex >= modelData.data.labels.length) {
            return Promise.resolve();
          }

          const automlModelId = modelData.data.labels[modelIndex].assignedAutomlModel;
          if (automlModelId) {
            return this.modelBackend.getAutomlTMLPerformance(automlModelId).toPromise().then(perfValues => {
              performanceData.set(modelData.data.labels[modelIndex].name, perfValues);
              modelIndex++;
              return doNext();
            });
          } else {
            // This should not happen in production, but may happen with unpublished CDSS apps
            modelIndex++;
            return doNext();
          }
        };

        return doNext();
      }
    }).then(() => {
      return this.backend.getRenderList(params.submissionId, params.sharingCode).toPromise();
    })
    .then(rrs => {

      let orderingMap = new Map<string,number>();
      let orderIndex = 0;
      modelData.rendering.scenes.forEach(scene => {
        let views: string[];

        if (Array.isArray(scene.camera))
          views = scene.camera;
        else {
          views = [scene.camera];
        }

        if (views.length > 1) {
          views.forEach(view => {
            orderingMap.set(`${scene.name}-${view}`, orderIndex);
            orderIndex++;
          });
        } else {
          orderingMap.set(`${scene.name}`, orderIndex);
          orderIndex++;
        }
      });

      rrs = rrs.filter(render => !render.startsWith("hv_"));

      // This filter() is here only to eliminate any discrepancies.
      // Under normal circumstances, it shouldn't filter anything out.
      rrs = rrs.filter(render => {
        let baseName = render.replace(/\.[^/.]+$/, "");
        return orderingMap.has(baseName);
      });

      renderList = rrs.sort((a, b) => {
        let bA = a.replace(/\.[^/.]+$/, "");
        let bB = b.replace(/\.[^/.]+$/, "");

        return orderingMap.get(bA) - orderingMap.get(bB);
      });
      
      let promises: Promise<ArrayBuffer>[] = [];

      for (let i = 0; i < renderList.length; i++) {
        let promise = this.backend.getRender(params.submissionId, renderList[i], params.sharingCode).toPromise();
        promises.push(promise);
      }

      return Promise.all(promises);
    })
    .then(renderContents => {

      for (let i = 0; i < renderList.length; i++)
        renderedFiles[renderList[i]] = renderContents[i];

      let extractedFeatures = this.generateExtractedFeaturesArray(modelData, submissionData, reportData);
      let predictions = this.generatePredictionsArray(modelData, reportData, performanceData);
      let manualFeatures = this.generateManualFeaturesArray(modelData, submissionData, reportData);

      let features = extractedFeatures.concat(manualFeatures).sort((a, b) => {
        let rankA = this.getAverageFeatureRank(modelData, reportData, a.realName);
        let rankB = this.getAverageFeatureRank(modelData, reportData, b.realName);
        return rankB - rankA;
      });

      let overrides: ReportContext['overrides'] = [];

      Object.keys(submissionData.overrides).forEach(roleId => {
        let role = modelData.data.roles.find(r => r.id === roleId);

        submissionData.overrides[roleId].forEach(problem => {
          let foundTag = matchableDicomTags.find(t => t.id === problem.tag);

          overrides.push({
            role: roleId,
            roleName: role.description,
            tag: foundTag?.name ?? problem.tag,
            expectedValue: problem.expectedValue,
            actualValue: problem.actualValue,
          });
          
        });
      });

      let context: ReportContext = {
        model: modelData,
        submissionDetails: {
          id: submissionData.uniqueStringId || params.submissionId,
          dateTime: submissionData.createdAt,
          user: params.userIdentification,
          supplier: params.cdssSupplierName,
        },
        patientData,
        features,
        predictions,
        renders: renderList,
        institutionName: params.institutionProfile?.institutionName,
        institutionLogo: params.institutionProfile?.institutionLogo,
        indications: submissionData.indicationTexts ?? [],
        overrides,
      };

      return this.cdssReport.distillPdf(modelData[params.field], context, renderedFiles);
    });
  }

  private shortenString(str: string, max: number): string {
    if (str.length > max)
      return str.substring(0, max - 1) + '…';
    return str;
  }

  private getAverageFeatureRank(model: CdssModel, report: CdssReportData, featureName: string): number {
    let ranks = [];

    model.data.labels.forEach(label => {
      let outcomeProbabilities = report.predictionData[label.name];

      if (outcomeProbabilities['outcomes']) {
        let pred = outcomeProbabilities as CdssReportPredictionData;

        if (pred.integrityMetadata[featureName]) {
          ranks.push(pred.integrityMetadata[featureName].rank);
        }
      }
    });

    if (ranks.length > 0)
      return ranks.reduce((acc, val) => acc+val, 0) / ranks.length;
    else
      return 0;
  }

  private generateExtractedFeaturesArray(model: CdssModel, submission: CdssSubmissionItem, report: CdssReportData) {
    let result: CdssExtractedFeature[] = [];

    let roleVariantMap = new Map<string, RoleAndVariant>();

    model.data.roles.forEach(role => {
      role.variants.forEach(variant => {
        roleVariantMap.set(variant.id, { role, variant });
      });
    });

    let featureNiceNames = new Map<string,string>();

    Object.keys(definedRadiomicsFeatures).forEach(grpId => {
      definedRadiomicsFeatures[grpId].features.forEach(ft => {
        featureNiceNames.set(ft.id, ft.friendlyName);
      });
    });

    for (let bodyRegion of model.data.bodyRegions) {

      if (!bodyRegion.featureExtraction)
        continue;

      model.data.extractedFeatures.forEach(ft => {
        let source: string[] = [], note: string[] = [];

        ft.roleVariants.forEach(variant => {
          let data = roleVariantMap.get(variant);
          let res: string[] = [];

          if (data.variant.resolution[0] === data.variant.resolution[1] && data.variant.resolution[1] === data.variant.resolution[2]) {
            res.push(data.variant.resolution[0].toFixed(1));
          } else {
            res.push(data.variant.resolution[0].toFixed(1));
            res.push(data.variant.resolution[1].toFixed(1));
            res.push(data.variant.resolution[2].toFixed(1));
          }

          source.push(data.role.description || data.role.id);
          note.push($localize `BS: ${data.variant.binWidth}, RES: ${res.join('/')}mm`);
        });

        let name = featureNiceNames.get(ft.feature);

        if (name) {
          if (name.length > 38)
            name = name.substring(0, 38) + "…";
          name = `${name} (${ft.feature})`;
        } else
          name = ft.feature;

        const allVariantsStr = ft.roleVariants.join('+');
        let fullFeatureName = `${allVariantsStr}.${ft.feature}`;

        if (bodyRegion.featureNamePrefix)
          fullFeatureName = bodyRegion.featureNamePrefix + '.' + fullFeatureName;

        let exFt: CdssExtractedFeature = {
          name,
          realName: fullFeatureName,
          value: submission.features[fullFeatureName],
          status: this.getFeatureStatuses(fullFeatureName, model, report),
          source: source.join('; '),
          note: note.join('; '),
        };

        result.push(exFt);
      });
    }

    let dicomTagMap = new Map(dicomTags.map(tag => [tag.id, tag]))

    model.data.roles.forEach(role => {

      role.extractedTags?.forEach(extractedTag => {
        const fullFeatureName = `${role.id}.dicom.${extractedTag.tag}`;

        let exFt: CdssExtractedFeature = {
          name: dicomTagMap.get(extractedTag.tag).name,
          realName: `${role.id}.${extractedTag.tag}`,
          status: this.getFeatureStatuses(fullFeatureName, model, report),
          value: submission.features[fullFeatureName],
          source: role.description || role.id,
          note: $localize `DICOM tag ${extractedTag.tag}`,
        };
  
        result.push(exFt);
      });
    });

    return result;
  }

  private generateManualFeaturesArray(model: CdssModel, submission: CdssSubmissionItem, report: CdssReportData) {
    let result: CdssExtractedFeature[] = [];

    model.data.manualFeatures?.forEach(manualFeature => {
      let exFt: CdssExtractedFeature = {
        name: manualFeature.niceName || manualFeature.name,
        realName: manualFeature.name,
        value: submission.features[manualFeature.name],
        source: $localize `Submission`,
        status: this.getFeatureStatuses(manualFeature.name, model, report),
        note: "-",
      };

      result.push(exFt);
    });

    return result;
  }

  private getFeatureStatuses(featureName: string, model: CdssModel, report: CdssReportData) {
    let rv: DataIntegrityStatus[] = [];

    model.data.labels.forEach(label => {
      let outcomeProbabilities = report.predictionData[label.name];

      if (outcomeProbabilities['outcomes']) {
        let pred = outcomeProbabilities as CdssReportPredictionData;

        if (pred.integrityMetadata[featureName]) {
          rv.push(pred.integrityMetadata[featureName].status);
        }
      }
    });

    return rv;
  }

  private generatePredictionsArray(model: CdssModel, report: CdssReportData, performanceDataMap: Map<string, AutoMLPublicPerformance>) {
    let result = [] as CdssPrediction[];

    model.data.labels.forEach(label => {
      let outcomeProbabilities = report.predictionData[label.name];
      let reactiveMetadata: AutoMLReactiveMetadata;
      let integrityMetadata: DataIntegrityMetadataWithName[];
      let confidence: number;
      let performanceData: PerformanceData;

      if (outcomeProbabilities['outcomes']) {
        let pred = outcomeProbabilities as CdssReportPredictionData;
        reactiveMetadata = pred.reactiveMetadata;
        integrityMetadata = orderDataIntegrityEntries(pred.integrityMetadata);
        outcomeProbabilities = pred.outcomes;
      }

      if (!outcomeProbabilities) {
        console.error(`Label value of label ${label.name} not generated in this report?`);
        return;
      }

      let results = [] as CdssPredictionResult[];

      if (label.allowedValues) {
        label.allowedValues.forEach((value, index) => {
          let color = ColorRange.colorToRgb(label.allowedValuesExtras?.[index]?.color, [255,255,255]).map(v => v / 255.0);
          results.push({
            name: label.allowedValuesExtras?.[index]?.niceName || value,
            value: outcomeProbabilities[value],
            color,
          });
        });
      } else {
        console.error("Allowed label values not defined, using fallback code");

        Object.keys(outcomeProbabilities).forEach(value => {
          results.push({
            name: value,
            value: outcomeProbabilities[value],
            color: [1,1,1],
          });
        });
      }

      let probabilitySum = results.reduce((acc, val) => {
        return acc + val.value;
      }, 0);
      results.forEach(result => result.value /= probabilitySum);

      let starGraph = "";
      if (reactiveMetadata) {
        let weightSum = 0;
        let confidenceAccum = 0;
        
        starGraph = generateEvaluationConfidenceMapTikz(label.name, reactiveMetadata);

        Object.keys(reactiveMetadata).forEach(neighborName => {
          let reactiveRecord: number[] = reactiveMetadata[neighborName];
          let weight = reactiveRecord[1];
          let correctRatio = reactiveRecord[2];

          weightSum += weight;
          confidenceAccum += correctRatio * weight;
        });

        confidence = confidenceAccum / weightSum;
      }

      let ringGraph = "";
      let integrityScore: number;
      if (integrityMetadata) {
        ringGraph = generateDataIntegrityMapTikz(integrityMetadata);
        integrityScore = calculateDataIntegrityScore(integrityMetadata);
      }

      let folds: number;
      let split;
      let cdssModelBuilderDescription: string;

      if (performanceDataMap.has(label.name)) {
        const data = performanceDataMap.get(label.name);
        folds = data.folds;
        split = data.split;
        cdssModelBuilderDescription = data.cdssModelBuilderDescription;
        performanceData = PerformanceData.fromPerformanceValues(data.performance);
      }

      result.push({
        label: label.niceName || label.name,
        description: label.description ?? "",
        confidence,
        integrityScore,
        results,
        tikzStarGraph: starGraph,
        tikzRingGraph: ringGraph,
        performanceData,
        split,
        folds,
        modelDescriptiveText: cdssModelBuilderDescription,
      });
    });

    return result;
  }

}

export interface InstitutionProfile {
  institutionName?: string;
  institutionLogo?: ArrayBuffer;
}

export interface ReportGenerationParams {
  crypto: Crypto;

  submissionId: string;
  field: "reportContents" | "reportContentsQC";
  institutionProfile?: InstitutionProfile;

  // One of
  privateKey?: string;
  sharingCode?: string;

  userIdentification: string; // name / email
  cdssSupplierName: string;
}

interface RoleAndVariant {
  role: CdssRole;
  variant: ProjectTemplateRoleVariant;
};


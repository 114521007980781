import { enableProdMode, Testability } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


/**
 * Used by Cypress.waitForAngularIdle() (in commands.ts) to wait for all Pending request to finish
 */
  function ngIsStable() {
  const test = (window as any).getAngularTestability(window.document.querySelector('body div')) as Testability; // you need to select an element managed by Angular. Usually any div in your html body

  const stable = test.isStable();
  return stable;
}

window['ngIsStable'] = ngIsStable;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionAmountPipe } from './transaction-amount.pipe';
import { ToFixedWidthPipe } from './to-fixed-width.pipe';
import { ToFixedPipe } from 'cdss-common-lib';
import { ToFixedSmartPipe } from './to-fixed-smart.pipe';
import { DurationPipe } from './duration.pipe';
import { SecondsSincePipe } from './seconds-since.pipe';



@NgModule({
  declarations: [
    TransactionAmountPipe,
    ToFixedWidthPipe,
    ToFixedSmartPipe,
    DurationPipe,
    SecondsSincePipe,
  ],
  providers: [
    TransactionAmountPipe,
    ToFixedPipe,
    ToFixedWidthPipe,
    ToFixedSmartPipe,
    DurationPipe,
    SecondsSincePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TransactionAmountPipe,
    ToFixedWidthPipe,
    ToFixedSmartPipe,
    DurationPipe,
    SecondsSincePipe,
  ]
})
export class CommonPipesModule { }

<clr-modal [(clrModalOpen)]="opened" [clrModalClosable]="!busy">
	<h3 class="modal-title">Delete account</h3>
	<div class="modal-body">
		<p>
			Are you sure you want to initiate the permanent deletion of your user account and all of its associated data?
		</p>
		<p>
			This procedure will require that you confirm the deletion by following a link delivered to your e-mail address.
		</p>
	</div>
	<div class="modal-footer">
		<span class="spinner spinner-inline" *ngIf="busy">
			Busy...
		</span>

		<button class="btn btn-outline" type="button" (click)="opened = false" [disabled]="busy">Cancel</button>
		<button class="btn btn-danger" type="button" (click)="doDelete()" [disabled]="busy">Delete</button>
	</div>
</clr-modal>

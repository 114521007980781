import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent {
  opened = false;
  @Input() message: string;
  @Input() title = "Dedicaid";

  constructor() { }

  public open(): void {
    this.opened = true;
  }

}

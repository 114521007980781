import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserProfile } from '../../authentication/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-settings-account',
  templateUrl: './settings-account.component.html',
  styleUrls: ['./settings-account.component.css']
})
export class SettingsAccountComponent {
  busy: number = 0;
  modalAccountOpen: boolean = false;

  profileForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl(''),
    jobTitle: new FormControl('')
  });

  constructor(private authenticationService: AuthenticationService) { }

  public openAccountSettings() {
    this.modalAccountOpen = true;

    this.busy++;
      this.authenticationService.getProfile(this.authenticationService.uid)
      .pipe(catchError(err => {
        window.alert("Error loading profile contents!");
        return of(null);
      }))
      .subscribe((profile: UserProfile) => {
        this.busy--;
        if (!profile)
          return;

        this.profileForm.get('name').setValue(profile.name || '');
        this.profileForm.get('email').setValue(profile.email);
        this.profileForm.get('phone').setValue(profile.phone || '');
        this.profileForm.get('jobTitle').setValue(profile.affiliation || '');

        this.profileForm.markAsPristine();
      });
  }

  saveAccountSettings() {
    if (this.profileForm.invalid) {
      this.profileForm.markAsTouched();
      return;
    }

    this.busy++;

    let profile: UserProfile = {
      name: this.profileForm.get('name').value,
      email: this.profileForm.get('email').value,
      phone: this.profileForm.get('phone').value,
      affiliation: this.profileForm.get('jobTitle').value
    };

    this.authenticationService.modifyProfile(this.authenticationService.uid, profile)
    .pipe(catchError(err => {
      window.alert("Error loading profile contents!");
      return of(null);
    }))
    .subscribe(res => {
      this.busy--;
      this.modalAccountOpen = false;
    });
  }

}

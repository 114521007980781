import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { PerformanceData } from '../performance';
import { ToFixedPipe } from '../to-fixed.pipe';
import { DvipdfmxEngine } from '../swift-latex/dvipdfmxEngine';
import { LaTeXEngine } from '../swift-latex/latexEngine';
import JSZip from 'jszip';
import { parsePatientNameString } from '../dicom-definitions';
import { CdssModel } from '../submission/model';
import { CdssSubmissionProblem } from '../submission/submission-backend.service';
import { DataIntegrityStatus } from '../data-integrity-map';
import Handlebars from "handlebars/dist/handlebars.js"
import { ProviderLogoLoader } from './provider-logo';

export const PROVIDER_LOGO = new InjectionToken("PROVIDER_LOGO");
export const TEXLIVE_BASE_URL = new InjectionToken("TEXLIVE_BASE_URL");
export const LATEX_WORKER = new InjectionToken("LATEX_WORKER");
export const DVIPDFMX_WORKER = new InjectionToken("LATEX_WORKER");

@Injectable({
  providedIn: 'any'
})
export class CdssReportService implements OnDestroy {
  private latexEngine: LaTeXEngine;
  private pdfEngine: DvipdfmxEngine;

  private _allReady: Promise<void>;
  private initDone = false;
  private handlebars: typeof Handlebars;
  private busyPromise = Promise.resolve();
  private providerLogo: ArrayBuffer;

  constructor(private http: HttpClient, private toFixed: ToFixedPipe, @Inject(PROVIDER_LOGO) providerLogo: ProviderLogoLoader,
    @Inject(TEXLIVE_BASE_URL) texliveBaseUrl: string, @Optional() @Inject(LATEX_WORKER) latexWorker?: string,
    @Optional() @Inject(DVIPDFMX_WORKER) dvipdfmxWorker?: string) {

    this.pdfEngine = new DvipdfmxEngine();
    this._allReady = this.pdfEngine.loadEngine(dvipdfmxWorker).then(() => {
      console.log("PDF engine loaded")

      if (texliveBaseUrl)
        this.pdfEngine.setTexliveEndpoint(texliveBaseUrl);

      this.latexEngine = new LaTeXEngine('XeLaTeX', latexWorker);
      return this.latexEngine.loadEngine();
    })
    .then(() => {
      console.log("XeTeX engine loaded");

      if (texliveBaseUrl)
        this.latexEngine.setTexliveEndpoint(texliveBaseUrl);

      return providerLogo.getLogoPDF();
    })
    .then(providerLogo => {
      this.providerLogo = providerLogo;
      //this.latexEngine.makeMemFSFolder("/work");

      return this.http.post(texliveBaseUrl, filesToPrefetch.join("\n"), { responseType: "arraybuffer" }).toPromise();
    })
    .then(texFilesZip => {

      return JSZip.loadAsync(texFilesZip).then(async (openedZip) => {
        for (const [name, data] of Object.entries(openedZip.files)) {
          // console.debug(`Writing pre-cached file ${name}`);
          this.latexEngine.writeMemFSFile(`../tex/${name}`, await (data as any).async("arraybuffer"));
        }
        this.initDone = true;
      });
      
    }, err => {
      console.error("Tex prefetch failed", err);
      this.initDone = true;
    })
    .catch(err => {
      console.error("Engine load error", err);
    });

    this.handlebars = Handlebars.create();
    
    this.handlebars.Utils.escapeExpression = function(str: any) {
      if (typeof str === 'string')
        return str.replace(/[&%$#_\{\}~\^\\]/g, "\\$&");
      return str;
    };
    

    this.handlebars.registerHelper("tex", function(str: string) {
      return str.replace(/[&%$#_\{\}~\^\\]/g, "\\$&");
    });

    this.handlebars.registerHelper("numeric", (val: number) => {
      if (typeof val !== 'number')
        return val;

      if (val > 10)
        return this.toFixed.transform(val, 2);

      return this.toFixed.transform(val, 4);
    });

    this.handlebars.registerHelper("formatDateTime", function(datetime: Date) {
      if (!datetime)
        return "";

      // Trick to use toISOString(), but with local time
      const date = new Date(datetime);
      date.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      const dateStr = date.toISOString();
      return dateStr.substring(0, 10) + ' ' + dateStr.substring(11, 19);
    });

    this.handlebars.registerHelper("formatDate", function(datetime: Date) {
      if (!datetime)
        return "";
        
      // Trick to use toISOString(), but with local time
      const date = new Date(datetime);
      date.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      const dateStr = date.toISOString();
      return dateStr.substring(0, 10);
    });

    this.handlebars.registerHelper("dicomPatientName", function(fulltext: string) {
      if (!fulltext)
        return "";

      let parsed = parsePatientNameString(fulltext);
        
      let formatted = texHyphenate(parsed.alphabetic.lastName);
      if (parsed.alphabetic.firstName) {
        formatted += ", " + texHyphenate(parsed.alphabetic.firstName);

        if (parsed.alphabetic.middleName)
          formatted += " " + texHyphenate(parsed.alphabetic.middleName);
      }

      return formatted;
    });

    this.handlebars.registerHelper("confidence", function(value: number) {
      if (value === null || value === undefined)
        return '';

      if (value > 0.75)
        return $localize `High`;
      else if (value > 0.25)
        return $localize `Moderate`;
      else
        return $localize `Low`;
    });

    this.handlebars.registerHelper("pct", function(value: number) {
      let pct: string;

      if (value === null || value === undefined)
        pct = "?";
      else
        pct = (100 * value).toFixed(0);

      return pct + "%";
    });

    this.handlebars.registerHelper("chartbar", function(color: string, probability: number, certainty: number) {
      const barwidth = 3;

      return new Handlebars.SafeString(
        `\\begin{tikzpicture}
      \\draw[draw=white,transparent] (0,0) rectangle (${barwidth},0.55);
      \\filldraw[fill=${color},draw=${color}] (0,0.35) rectangle ++(${probability * certainty * barwidth},-0.3);
      \\draw[dashed,draw=${color}] (${probability * certainty * barwidth},0.35) rectangle ++(${probability * (1-certainty) * barwidth},-0.3);
      \\end{tikzpicture}`
      );
    });

    this.handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {

      switch (operator) {
          case '==':
              return (v1 == v2) ? options.fn(this) : options.inverse(this);
          case '===':
              return (v1 === v2) ? options.fn(this) : options.inverse(this);
          case '!=':
              return (v1 != v2) ? options.fn(this) : options.inverse(this);
          case '!==':
              return (v1 !== v2) ? options.fn(this) : options.inverse(this);
          case '<':
              return (v1 < v2) ? options.fn(this) : options.inverse(this);
          case '<=':
              return (v1 <= v2) ? options.fn(this) : options.inverse(this);
          case '>':
              return (v1 > v2) ? options.fn(this) : options.inverse(this);
          case '>=':
              return (v1 >= v2) ? options.fn(this) : options.inverse(this);
          case '&&':
              return (v1 && v2) ? options.fn(this) : options.inverse(this);
          case '||':
              return (v1 || v2) ? options.fn(this) : options.inverse(this);
          default:
              return options.inverse(this);
      }
    });

    this.handlebars.registerHelper({
      eq: (v1, v2) => v1 === v2,
      ne: (v1, v2) => v1 !== v2,
      lt: (v1, v2) => v1 < v2,
      gt: (v1, v2) => v1 > v2,
      lte: (v1, v2) => v1 <= v2,
      gte: (v1, v2) => v1 >= v2,
      and() {
          return Array.prototype.every.call(arguments, Boolean);
      },
      or() {
          return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
      }
    });

    this.handlebars.registerHelper('ifIsNthItem', function(options) {
      var index = options.data.index + 1,
          nth = options.hash.nth;
    
      if (index % nth === 0) 
        return options.fn(this);
      else
        return options.inverse(this);
    });
  }

  ngOnDestroy(): void {
    this.pdfEngine?.closeWorker();
    this.latexEngine?.closeWorker();
  }

  public get allReady() {
    return this._allReady;
  }

  public async distillPdf(texFileContents: string, context: ReportContext, renderedFiles: RenderedFiles): Promise<Blob> {
    await this._allReady;

    context.predictions.forEach(pred => {
      pred.results.sort((a, b) => b.value - a.value);
    });

    let promise = this.busyPromise.then(() => {
      let template = this.handlebars.compile(texFileContents);

      // Tried using \\_, but it ended up making the TeX engine loop forever
      context.renders = context.renders.map(fileName => {
        let safeFilename = fileName.replace(/_/g, "-");
        return safeFilename;
      });

      let processedTex = template(context);

      this.latexEngine.flushCache();

      this.latexEngine.writeMemFSFile("/provider.pdf", this.providerLogo);
      this.pdfEngine.writeMemFSFile("/provider.pdf", this.providerLogo);

      this.latexEngine.makeMemFSFolder("/render");
      this.pdfEngine.makeMemFSFolder("/render");

      this.latexEngine.writeMemFSFile("main.tex", processedTex);

      if (context.institutionLogo) {
        this.latexEngine.writeMemFSFile("/institution.png", context.institutionLogo);
        this.pdfEngine.writeMemFSFile("/institution.png", context.institutionLogo);
      }

      Object.keys(renderedFiles).forEach(fileName => {
        let safeFilename = fileName.replace(/_/g, "-");

        this.latexEngine.writeMemFSFile(`/render/${safeFilename}`, renderedFiles[fileName]);
        this.pdfEngine.writeMemFSFile(`/render/${safeFilename}`, renderedFiles[fileName]);
      });

      return this.latexEngine.compileLaTeX(true).then(result => {
        console.log("LaTeX result:", result);

        if (result.status !== 0)
          throw result;

        // Do a second pass to fix problems with certain tables
        return this.latexEngine.compileLaTeX(true);
      })
      .then(result => {
        this.pdfEngine.writeMemFSFile("main.xdv", result.pdf);
        return this.pdfEngine.compilePDF();
      })
      .then(result => {
        if (result.status === 0) {
          var blob = new Blob([ result.pdf ], {'type': 'application/pdf'});
          return blob;
        } else {
          throw result;
        }
      });
    });

    this.busyPromise = promise.then(() => {}, () => {});

    return promise;
  }
}

type RenderedFiles = {[name: string]: ArrayBuffer};

export interface ReportContext {
  model: CdssModel;
  // providerLogo?: Blob;
  institutionLogo?: ArrayBuffer;
  institutionName: string;
  submissionDetails: ReportSubmissionDetails;
  patientData: CdssPatientData;
  features: CdssExtractedFeature[];
  predictions: CdssPrediction[];
  renders: string[];
  indications: string[];
  overrides: ReportSubmissionProblem[];
}

export interface ReportSubmissionProblem extends CdssSubmissionProblem {
  role: string;
  roleName: string;
}

export interface ReportSubmissionDetails {
  id: string;
  dateTime: Date;
  user: string;
  supplier: string;
}

export interface CdssPatientData {
  patientName: string;
  patientId: string;
  patientBirthDate: Date | string;
  age: string;
  sex: string;
  height: number;
  weight: number;

  studyDate: Date | string;
  studyId: string;
  referringPhysician: string;
  institute: string;
}

export interface CdssExtractedFeature {
  name: string; // Name to show
  realName: string; // Name used in submission/report
  value: any;
  source: string;
  status: DataIntegrityStatus[];
  note?: string;
}

export interface CdssPrediction {
  label: string; // Nice name if available
  description: string; // Explanatory text
  results: CdssPredictionResult[];
  confidence: number;
  integrityScore: number;
  tikzStarGraph: string; // confidence
  tikzRingGraph: string; // integrity
  performanceData?: PerformanceData;
  folds?: number;
  split?: {
    validation: number;
    training: number;
  };
  modelDescriptiveText?: string; // CSF-377: Model builder's descriptive text
}

export interface CdssPredictionResult {
  name: string;
  value: number;
  color: number[];
}

function texHyphenate(word: string): string {
  if (word.length < 15)
    return word;

  return word.match(/(.{1,10})/g).join('\\-');
}

// Performance optimization (CSF-)
const filesToPrefetch = [
  "swiftlatex22p.fmt",
  "report.cls",
  "size12.clo",
  "lmroman12-regular.otf",
  "tex-text.tec",
  "arydshln.sty",
  "xltabular.sty",
  "tabularx.sty",
  "array.sty",
  "ltablex.sty",
  "longtable.sty",
  "fontspec.sty",
  "xparse.sty",
  "expl3.sty",
  "l3backend-xetex.def",
  "xparse-2020-10-01.sty",
  "xparse-generic.tex",
  "fontspec-xetex.sty",
  "tuenc.def",
  "fontenc.sty",
  "fontspec.cfg",
  "lmroman12-bold.otf",
  "multirow.sty",
  "hyperref.sty",
  "ltxcmds.sty",
  "iftex.sty",
  "pdftexcmds.sty",
  "infwarerr.sty",
  "keyval.sty",
  "kvsetkeys.sty",
  "kvdefinekeys.sty",
  "pdfescape.sty",
  "hycolor.sty",
  "letltxmacro.sty",
  "auxhook.sty",
  "kvoptions.sty",
  "pd1enc.def",
  "hyperref-langpatches.def",
  "intcalc.sty",
  "etexcmds.sty",
  "puenc.def",
  "hyperref.cfg",
  "url.sty",
  "pzdr.tfm",
  "bitset.sty",
  "bigintcalc.sty",
  "atbegshi.sty",
  "atbegshi-ltx.sty",
  "hxetex.def",
  "stringenc.sty",
  "rerunfilecheck.sty",
  "rerunfilecheck.cfg",
  "atveryend.sty",
  "atveryend-ltx.sty",
  "uniquecounter.sty",
  "graphicx.sty",
  "graphics.sty",
  "trig.sty",
  "graphics.cfg",
  "xetex.def",
  "fancyhdr.sty",
  "tikz.sty",
  "pgf.sty",
  "pgfrcs.sty",
  "pgfutil-common.tex",
  "pgfutil-common-lists.tex",
  "pgfutil-latex.def",
  "pgfrcs.code.tex",
  "pgf.revision.tex",
  "pgfcore.sty",
  "pgfsys.sty",
  "pgfsys.code.tex",
  "pgfkeys.code.tex",
  "pgfkeysfiltered.code.tex",
  "pgf.cfg",
  "pgfsys-xetex.def",
  "pgfsys-dvipdfmx.def",
  "pgfsys-common-pdf.def",
  "pgfsyssoftpath.code.tex",
  "pgfsysprotocol.code.tex",
  "xcolor.sty",
  "color.cfg",
  "pgfcore.code.tex",
  "pgfmath.code.tex",
  "pgfmathcalc.code.tex",
  "pgfmathutil.code.tex",
  "pgfmathparser.code.tex",
  "pgfmathfunctions.code.tex",
  "pgfmathfunctions.basic.code.tex",
  "pgfmathfunctions.trigonometric.code.tex",
  "pgfmathfunctions.random.code.tex",
  "pgfmathfunctions.comparison.code.tex",
  "pgfmathfunctions.base.code.tex",
  "pgfmathfunctions.round.code.tex",
  "pgfmathfunctions.misc.code.tex",
  "pgfmathfunctions.integerarithmetics.code.tex",
  "pgfmathfloat.code.tex",
  "pgfint.code.tex",
  "pgfcorepoints.code.tex",
  "pgfcorepathconstruct.code.tex",
  "pgfcorepathusage.code.tex",
  "pgfcorescopes.code.tex",
  "pgfcoregraphicstate.code.tex",
  "pgfcoretransformations.code.tex",
  "pgfcorequick.code.tex",
  "pgfcoreobjects.code.tex",
  "pgfcorepathprocessing.code.tex",
  "pgfcorearrows.code.tex",
  "pgfcoreshade.code.tex",
  "pgfcoreimage.code.tex",
  "pgfcoreexternal.code.tex",
  "pgfcorelayers.code.tex",
  "pgfcoretransparency.code.tex",
  "pgfcorepatterns.code.tex",
  "pgfcorerdf.code.tex",
  "pgfmoduleshapes.code.tex",
  "pgfmoduleplot.code.tex",
  "pgfcomp-version-0-65.sty",
  "pgfcomp-version-1-18.sty",
  "pgffor.sty",
  "pgfkeys.sty",
  "pgfmath.sty",
  "pgffor.code.tex",
  "tikz.code.tex",
  "pgflibraryplothandlers.code.tex",
  "pgfmodulematrix.code.tex",
  "tikzlibrarytopaths.code.tex",
  "adjustbox.sty",
  "xkeyval.sty",
  "xkeyval.tex",
  "xkvutils.tex",
  "adjcalc.sty",
  "trimclip.sty",
  "collectbox.sty",
  "tc-xetex.def",
  "ifoddpage.sty",
  "varwidth.sty",
  "lastpage.sty",
  "ragged2e.sty",
  "everysel.sty",
  "everysel-2011-10-28.sty",
  "setspace.sty",
  "wrapfig.sty",
  "makecell.sty",
  "geometry.sty",
  "ifvtex.sty",
  "geometry.cfg",
  "tikzlibrarypositioning.code.tex",
  "[Barlow-Regular].fontspec",
  "Barlow-Regular.otf",
  "Barlow-Regular.ttf",
  "Barlow-SemiBold.otf",
  "Barlow-SemiBold.ttf",
  "[Barlow-SemiBold].fontspec",
  "main.aux",
  "ts1cmr.fd",
  "color.sty",
  "nameref.sty",
  "refcount.sty",
  "gettitlestring.sty",
  "gettitlestring.cfg",
  "main.out",
  "cmmi12.tfm",
  "cmmi8.tfm",
  "cmmi6.tfm",
  "cmsy10.tfm",
  "cmsy8.tfm",
  "cmsy6.tfm",
  "cmr12.tfm",
  "cmr8.tfm",
  "cmr6.tfm",
  "cmr17.tfm",
];

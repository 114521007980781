import { NamedBlob, NormalizeByValue, SerieUid } from "../dicom-common";
import { DicomSerie, dicomSUVNormalization } from "../dicom-definitions";
import { DicomAnalysisResultSerie } from "../dicom.service";
import { CdssModel } from "./model";

export function prepareSeriesForUpload(roleAssignment: Map<string,string>, loadedFiles: Map<string, NamedBlob> | null,
    serieUidMap: Map<SerieUid, DicomAnalysisResultSerie>, model: CdssModel,
    dicomValues: object): (DicomSerie & NormalizeByValue)[] {

    
    let series = new Map<string, DicomSerie & NormalizeByValue>();
    let roleAssignmentForServer = new Map<string, string>(); // role.id -> uploaded filename

    model.data.roles.forEach(role => {
      let serieUid = roleAssignment.get(role.id);
  
      const normalizeLinear = dicomSUVNormalization(role, dicomValues);
      let filename: string;
  
      if (normalizeLinear === undefined)
        filename = `${serieUid}.nii.gz`;
      else
        filename = `${serieUid}-SUV.nii.gz`;
  
      if (!series.has(filename)) {
        const serieData = serieUidMap.get(serieUid);
        let files = loadedFiles ? serieData.filenames.map(filename => loadedFiles.get(filename)) : null;
  
        series.set(filename, {
          files,
          filenames: serieData.filenames,
          targetFileName: filename,
          friendlyName: serieData.description || serieData.modality,
          normalizeByValue: normalizeLinear,
        });
      }

      roleAssignmentForServer.set(role.id, filename);
    });

    //dicomUpload.submitDicomSeries(Array.from(series.values()));
    return Array.from(series.values());
  }

  // Converts a role id -> series UID map into role id -> filename
export function roleAssignmentForServer(model: CdssModel, roleAssignment: Map<string,string>): Map<string,string> {
    let roleAssignmentForServer = new Map<string, string>(); // role.id -> uploaded filename

    model.data.roles.forEach(role => {
      let serieUid = roleAssignment.get(role.id);
      let filename: string;
  
      if (!role.normalizeToSUV)
        filename = `${serieUid}.nii.gz`;
      else
        filename = `${serieUid}-SUV.nii.gz`;


      roleAssignmentForServer.set(role.id, filename);
    });

    return roleAssignmentForServer;
  }
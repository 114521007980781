<clr-modal [(clrModalOpen)]="showModal" [clrModalSize]="'lg'">
	<h3 class="modal-title">Invoicing settings</h3>
	<div class="modal-body">
		<form clrForm [formGroup]="invoicingForm">
			<clr-input-container>
				<label>Company Name</label>
				<input clrInput formControlName="companyName" size="40" autocomplete="organization" />
			</clr-input-container>

			<clr-input-container>
				<label>Company Registration ID</label>
				<input clrInput formControlName="companyID" size="40" />
			</clr-input-container>

			<clr-input-container>
				<label>VAT ID</label>
				<input clrInput formControlName="vatID" size="40" />
			</clr-input-container>

			<clr-input-container>
				<label>Address line 1</label>
				<input clrInput formControlName="addressLine1" size="40" autocomplete="billing address-line1" />
			</clr-input-container>

			<clr-input-container>
				<label>Address line 2</label>
				<input clrInput formControlName="addressLine2" size="40" autocomplete="billing address-line2" />
			</clr-input-container>

			<clr-input-container>
				<label>City</label>
				<input clrInput formControlName="city" size="40" autocomplete="billing address-level2" />
			</clr-input-container>

			<clr-input-container>
				<label>State</label>
				<input clrInput formControlName="state" size="40" autocomplete="billing address-level1" />
			</clr-input-container>

			<clr-input-container>
				<label>Postal code</label>
				<input clrInput formControlName="postalCode" size="40" autocomplete="billing postal-code" />
			</clr-input-container>

			<clr-select-container>
				<label>Country</label>
				<select clrSelect name="country" formControlName="country">
					<option *ngFor="let country of countries" [ngValue]="country.code">{{ country.name }}</option>
				</select>
			</clr-select-container>

			<!--
			<clr-input-container>
				<label>Billing Period</label>
				<select clrInput formControlName="billingPeriod">
					<option value="monthly">Monthly</option>
					<option value="quarterly">Quarterly</option>
					<option value="yearly">Yearly</option>
				</select>
			</clr-input-container>
			-->
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline" (click)="showModal = false">Close</button>
		<button type="button" class="btn btn-primary" (click)="saveInvoicingSettings()" [disabled]="busy">Save</button>
	</div>
</clr-modal>
